import { createContext, useEffect, useState } from "react";
import Default from "../../components/dafault";
import DefaultWrapper from "../../components/defaultWrapper";
import Timeføring from "./timeføring";
import TopBarChoosePage from "../../components/miniComponents/choosePageTopBar";
import TimeOverview from "./timeOverview";
import Timeliste from "./timeliste";
import { CheckIfOnPC, TimeContextProvider } from "../../App";
import FørteTimer from "./førte-timer";
import TopBarChoosePageGoogleVersion from "../../components/miniComponents/choosePageTopBarGoogleVersion";
import { AccessAlarm, AccessTime, Alarm, CalendarTodayRounded, List, Pages } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function TimeTrackerMenu() {
    const { t } = useTranslation()

    useEffect(() => {
        //getTimeTrackedForUser()
    }, [])

    return (
        <TimeContextProvider>
            <DefaultWrapper noBackButton={true} header={true} noPaddingTop={true}>
                <div className="column">
                <TopBarChoosePageGoogleVersion options={[
                    { name: t("Time management", "Timeføring"), component: <Timeføring />, icon: Alarm },
                    
                    { name: t("Registered Hours", "Førte Timer"), component: <FørteTimer />, icon: AccessTime },
                    { name: t("Work Schedule", "Arbeidsplan"), component: <Timeliste />, icon: CalendarTodayRounded },
                    { name: t("Overview", "Oversikt"), component: <TimeOverview />, icon: Pages }
                ]} />
                    {/*
                        <TopBarChoosePage options={[{ name: "Timeføring", component: <Timeføring /> }, { name: "Arbeidsplan", component: <Timeliste /> }, { name: "Oversikt", component: <TimeOverview /> }]} />
                    */}
                </div>
            </DefaultWrapper>
        </TimeContextProvider>
    )
}