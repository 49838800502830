import { db, app, getCompany, getCompanyId } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';








// Create a new company
export async function checkCouponCode(string) {
    const functions = getFunctions(app, 'europe-west2');
    const checkSalesCode = httpsCallable(functions, 'checkCouponCode');
    const data = await checkSalesCode({ couponCode: string });
    if (data?.error) {
        throw new Error(data.error);
    }
    return data;
}