import { useEffect, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import CustomDropdown from "../../../components/miniComponents/customDropdown";
import { useHistory } from "react-router";
import { getMals } from "../../../firebase";
import { on } from "events";
import { useTranslation } from "react-i18next";




export default function ReportSettings(props) {
    const { t } = useTranslation();
    const [kjemaTyper, setKjemaTyper] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const onUpdated = (mals) => {
            setKjemaTyper(mals)
        }

        getMals(onUpdated)
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });

    }

    return (
        <DefaultWrapper>
            <div className="column content-ny">
                <h1>{t("Report template", "Rapport Maler")}</h1>
                <p>{t("Here you can add or remove types", "Her kan du legge til og fjerne mal typer")}</p>
                <div className="row">
                    <button className="bottom-right-button" onClick={
                        () => {
                            history.push('/reportSettings/nyMal')
                        }
                    }>{t("Add", "Legg til")}</button>
                </div>
                <div className="column button-list">
                    {
                        kjemaTyper.map((kjemaType) => {
                            return (
                                <ButtonListButton
                                    title={kjemaType.title}
                                    description={kjemaType.description}
                                    //navigateTo={`./reportSettings/${kjemaType.id}`}
                                    key={kjemaType.id}
                                    onClick={() => {
                                        //give kjemaType to next page
                                        const mal = {mal: kjemaType}
                                        navigateToNewPage(`/reportSettings/${kjemaType.id}`, mal)
                                    }}
                                    icon={kjemaType.icon}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </DefaultWrapper>
    )
}



/*
{
oppgaver: [
    {
        title: "Mål",
        kanHaFlere: true,
        id: "123"
        underAlignments: [
            {
                name: "stk",
                type: "number"
            },
            {
                name: "kr",
                type: "number"
            },
            {
                name: "timer",
                type: "number"
            }
        ]
    }
]
}
*/