import { IonDatetime } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import TimePicker from 'react-time-picker';
import TimePickerCarousel from '../miniComponents/calendarInput';
import { on } from 'events';
import { Capacitor } from '@capacitor/core';
import CustomInput from '../miniComponents/customInput';
import { createDateOutOfFirebaseTimestamp } from '../../pages/timeføring/timeføring';

function TimeChooser({ date, onChange, type }) {
    const [time, setTime] = useState(createDateOutOfFirebaseTimestamp(date));

    /*useEffect(() => {
        console.log(date)
        setTime(date);
    }, [date])*/

    const onChangeInComponent = newTime => {
        setTime(newTime);
        console.log(`New time selected: ${newTime}`);
    };

    useEffect(() => {
        onChange(time);
    }, [time])

    console.log(time)

    /*if (!Capacitor.isNativePlatform()) return (
        <CustomInput type="datetime-local"
            onChange={(e) => {
                setTime(new Date(e.target.value))
            }
            } noIcon={true}
            value={new Date(time).toISOString().slice(0, 16)} />
    )*/

    /*return (
        <CustomInput
            type={type || "datetime-local"}
            onChange={(e) => {
                console.log(e.target.value);  // log the ISO string from the input
                const localDate = new Date(e.target.value);  // parse the ISO string to a Date object
                setTime(localDate);  // update the state with the local date
            }}
            noIcon={true}
            onClick={(e) => {
                // Open the date picker when the button is clicked
                e?.target?.focus();
                e?.target?.click();

            }}
            value={new Date(time.getTime() - time.getTimezoneOffset() * 60000).toISOString().slice(0, 16)}
        />
    )*/

    return (
        <div>
            <TimePickerCarousel onChange={onChangeInComponent} initialDate={time} />

            {/* REMOVED FOR NOW, NO LONGER USED
            <IonDatetime
                value={toLocalISO(time)}
                color={"secondary"}
                presentation="time"
                onIonChange={(e) => {
                    //console.log(new Date(e.detail.value))
                    //control for not infinite loop
                    if (new Date(e.detail.value).getTime() !== time.getTime()) setTime(new Date(e.detail.value))
                }}
                minuteValues={"0,15,30,45"}
            />*/}
        </div>
    );
}

const toLocalISO = (date) => {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num) => {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
};

export default TimeChooser;
