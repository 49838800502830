import { useHistory } from "react-router";

//assets\icons\mdi_arrow-left-bold.svg
import { ReactComponent as BackIcon } from '../../assets/icons/mdi_arrow-left-bold.svg';
import { useTranslation } from "react-i18next";

export default function TilbakeKnapp(props) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <button className='' onClick={async () => {
            if (props?.onClick) await props?.onClick();
            if (!props.skipNormalBehaviour) {
                history.goBack();
            }
        }
        }>
            <BackIcon></BackIcon>
            <p>{props?.lagring ? t("Save and ", "Lagre og ") : ""} {t("Back", "Tilbake")}</p>
        </button>
    );
}