import DefaultWrapper from "../../../components/defaultWrapper";
import CustomInput from "../../../components/miniComponents/customInput";

import { useEffect, useState } from "react";
import { saveSettings } from "./adminInstillinger";
import { createId, getFirmSettings } from "../../../firebase";
import { IonCheckbox } from "@ionic/react";
import CustomDropdown from "../../../components/miniComponents/customDropdown";
import { useTranslation } from "react-i18next";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { useHistory } from "react-router";



export default function RapportInstillinger() {
    const { t } = useTranslation();
    const [rapportInstillinger, setRapportInstillinger] = useState({
        brukStatus: false,
        statuser: [],
        defaultStatus: "",
        onSendStatus: "",
        email: "",
    });

    const history = useHistory();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSettings = async () => {
            let settings = await getFirmSettings('rapportInstillinger');
            if (!settings) return;
            setRapportInstillinger({ ...rapportInstillinger, ...settings });
            setLoading(false);
        }
        getSettings();
    }, []);

    useEffect(() => {
        if (rapportInstillinger.statuser.length === 0) {
            setRapportInstillinger((old) => {
                const newRapportInstillinger = { ...old };
                newRapportInstillinger.statuser.push({ navn: "Sendt", farge: "#0FA730", id: createId() });
                newRapportInstillinger.statuser.push({ navn: "Ikke sendt", farge: "#BF2E00", id: createId() });
                newRapportInstillinger.defaultStatus = newRapportInstillinger.statuser[0];
                return newRapportInstillinger;
            })
        }
    }, [rapportInstillinger.statuser.length === 0]);

    useEffect(() => {
        const checkIfChanged = () => {
            console.log(rapportInstillinger.defaultStatus);
            if (rapportInstillinger.statuser.length === 0 && rapportInstillinger.defaultStatus === "") return;
            if (rapportInstillinger.statuser.length === 0) return setRapportInstillinger((old) => {
                const newRapportInstillinger = { ...old };
                newRapportInstillinger.defaultStatus = "";
                return newRapportInstillinger;
            })

            const find = rapportInstillinger.statuser.find((status) => {
                return status?.id === rapportInstillinger?.defaultStatus?.id;
            });

            if (!find || find === undefined) return setRapportInstillinger((old) => {
                const newRapportInstillinger = { ...old };
                newRapportInstillinger.defaultStatus = newRapportInstillinger.statuser[0];
                return newRapportInstillinger;
            })

            console.log(rapportInstillinger.defaultStatus, find);

            if (rapportInstillinger.defaultStatus === "") return;

            if (find?.navn !== rapportInstillinger?.defaultStatus?.navn) {
                setRapportInstillinger((old) => {
                    const newRapportInstillinger = { ...old };
                    newRapportInstillinger.defaultStatus.navn = find.navn;
                    return newRapportInstillinger;
                })
            }
        }
        checkIfChanged();
    }, [rapportInstillinger]);

    useEffect(() => {
        const checkIfChanged = () => {
            if (rapportInstillinger.statuser.length === 0 && rapportInstillinger.onSendStatus === "") return;
            if (rapportInstillinger.statuser.length === 0) return setRapportInstillinger((old) => {
                const newRapportInstillinger = { ...old };
                newRapportInstillinger.onSendStatus = "";
                return newRapportInstillinger;
            })

            const find = rapportInstillinger.statuser.find((status) => {
                return status?.id === rapportInstillinger?.onSendStatus?.id;
            });
            console.log(find);

            if (!find) return setRapportInstillinger((old) => {
                const newRapportInstillinger = { ...old };
                newRapportInstillinger.onSendStatus = newRapportInstillinger.statuser[newRapportInstillinger.statuser.length - 1];
                return newRapportInstillinger;
            })

            console.log(rapportInstillinger.onSendStatus, find);

            if (rapportInstillinger.onSendStatus === "") return;

            if (find.navn !== rapportInstillinger.onSendStatus.navn) {
                setRapportInstillinger((old) => {
                    const newRapportInstillinger = { ...old };
                    newRapportInstillinger.onSendStatus.navn = find.navn;
                    return newRapportInstillinger;
                })
            }
        }
        checkIfChanged();
    }, [rapportInstillinger]);

    useEffect(() => {
        if (loading) return;
        saveSettings(rapportInstillinger, "rapportInstillinger");

    }, [rapportInstillinger]);


    return (
        <DefaultWrapper onBack={
            () => {
                saveSettings(rapportInstillinger, "rapportInstillinger");
            }
        }>
            <div className="content-ny">
                <h1>{t("Report settings", "Rapportinstillinger")}</h1>

                <CustomInput label={t("Report Email", "Rapport Epost")} value={rapportInstillinger?.email} onChange={(e) => {
                    setRapportInstillinger((old) => {
                        const newRapportInstillinger = { ...old };
                        newRapportInstillinger.email = e.target.value;
                        return newRapportInstillinger;
                    })
                }}></CustomInput>

                <div className="row flexApart">
                    <h3>{t("Use status on projects", "Bruk status på prosjekter")}</h3>
                    <IonCheckbox type="checkbox" label="Bruk status" checked={rapportInstillinger.brukStatus} onIonChange={e => {
                        setRapportInstillinger((old) => {
                            const newRapportInstillinger = { ...old };
                            newRapportInstillinger.brukStatus = e.target.checked;
                            return newRapportInstillinger;
                        }
                        );
                    }
                    } style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "42px",
                        maxHeight: "42px",
                    }} />
                </div>

                <div className="row flexApart">
                    <h3>{t("New Report status", "Ny Rapport status")}</h3>
                    <CustomDropdown onChange={(e) => {
                        console.log(e)
                        setRapportInstillinger((old) => {
                            const newRapportInstillinger = { ...old };
                            newRapportInstillinger.defaultStatus = e;
                            return newRapportInstillinger;
                        }
                        );
                    }} options={rapportInstillinger?.statuser}
                        selected={rapportInstillinger.defaultStatus}

                    ></CustomDropdown>
                </div>

                <div className="row flexApart">
                    <h3>{t("Send report status", "Sendt Rapport status")}</h3>
                    <CustomDropdown onChange={(e) => {
                        console.log(e)
                        setRapportInstillinger((old) => {
                            const newRapportInstillinger = { ...old };
                            newRapportInstillinger.onSendStatus = e;
                            return newRapportInstillinger;
                        }
                        );
                    }} options={rapportInstillinger?.statuser}
                        selected={rapportInstillinger.onSendStatus}

                    ></CustomDropdown>
                </div>

                {rapportInstillinger.brukStatus && (
                    <div className="column">
                        <h3>{t("Statuses", "Statuser")}</h3>
                        {rapportInstillinger?.statuser?.map((status, index) => {
                            return (
                                <div key={index} className="row flexApart center">
                                    <CustomInput label="Status" value={status.navn} onChange={(e) => {
                                        setRapportInstillinger((old) => {
                                            const newRapportInstillinger = { ...old };
                                            newRapportInstillinger.statuser[index].navn = e.target.value;
                                            return newRapportInstillinger;
                                        })
                                    }}></CustomInput>
                                    <input style={{
                                        aspectRatio: "1/1",
                                        width: "62px",
                                        height: "100%",
                                        borderRadius: "5px",
                                    }} label="Farge" type="color" value={status.farge} onChange={(e) => {
                                        setRapportInstillinger((old) => {
                                            const newRapportInstillinger = { ...old };
                                            newRapportInstillinger.statuser[index].farge = e.target.value;
                                            return newRapportInstillinger;
                                        })
                                    }}></input>
                                    <button onClick={() => {
                                        setRapportInstillinger((old) => {
                                            const newRapportInstillinger = { ...old };
                                            newRapportInstillinger.statuser.splice(index, 1);
                                            return newRapportInstillinger;
                                        })
                                    }}>{t("Delete", "Slett")}</button>
                                </div>
                            )
                        })}
                        <button onClick={() => {
                            setRapportInstillinger((old) => {
                                const newRapportInstillinger = { ...old };
                                newRapportInstillinger.statuser.push({ navn: "", farge: "#000000", id: createId() });
                                if (newRapportInstillinger.defaultStatus === "") newRapportInstillinger.defaultStatus = newRapportInstillinger.statuser[0];
                                return newRapportInstillinger;
                            })
                        }}>{t("Add status", "Legg til status")}</button>
                    </div>
                )}

                <div className="button-list">
                    <ButtonListButton onClick={() => {
                        saveSettings(rapportInstillinger, "rapportInstillinger");
                        history.push('/reportSettings');
                    }} title={t("Templates", "Maler")}></ButtonListButton>
                </div>
            </div>
        </DefaultWrapper >
    );
}