import { useTranslation } from "react-i18next";
import DefaultWrapper from "../../components/defaultWrapper";
import { useContext, useState } from "react";
import { UserContext } from "../../App";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";





export default function ParentalLeave() {
    return (
        <DefaultWrapper>
            <h1>Parental Leave</h1>
        </DefaultWrapper>
    )
}