import { useState } from "react"
import ButtonListButton from "./buttonListButton"

import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';


export default function ItemDropdownButtons({ items, onClick, className, style, title, doNotBumpIn }) {

    const [showDropdown, setShowDropdown] = useState(false)

    return (
        <div className="column stretch-width small-gap">
            <button type="button" title={title} className="stretch-width itemDropDownButton" style={style} onClick={() => {
                setShowDropdown(!showDropdown)
            }}>
                <div className={'row flexApart small-gap stretch-width center padding'}>
                    {title && <h2 className={'orange '}>{title}</h2>}
                    <DownArrow style={{
                        transform: showDropdown ? "rotate(0deg)" : "rotate(-90deg)",
                        transition: "transform 0.2s ease-in-out"
                    }}/>

                </div>
            </button>
            <div className="column small-gap stretch-width" style={{
                marginLeft: doNotBumpIn ? "0px" : "40px",
            }}>
                {
                    showDropdown && items
                }
            </div>
        </div>
    )
}