
import { ReactComponent as QuestionMarkIcon } from '../../assets/icons/material-symbols_question-mark-rounded.svg';


export default function CircleIcon(props) {
    //if (!props.icon) return (<div></div>)
    //console.log(props.icon)
    if (!props.icon) return (
        <div className={"circleIcon" + (`${props.good ? " good" : ""}`) + (props.recording ? " recording" : "")}>
            {
                !props?.icon && <QuestionMarkIcon></QuestionMarkIcon>
            }
        </div>
    )


    return (
        <div className={"circleIcon" + (`${props.good ? " good" : ""}`) + (props.recording ? " recording" : "")}>
            {typeof props.icon === "string" ?
                <i className={props.icon}></i>
                :
                <props.icon></props.icon>
            }
        </div>
    );
}