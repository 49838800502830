
// Create the context
import React, { createContext, useEffect, useState } from "react";

// Create the context
export const CompanyContext = createContext();

// Create the provider component
export const CompanyProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));

  useEffect(() => {
    // Function to update companyId when localStorage changes
    const updateCompanyIdState = () => {
      setCompanyId(localStorage.getItem("companyId"));
    };

    // Listen for changes within the same tab using a custom event
    window.addEventListener("companyIdChange", updateCompanyIdState);

    // Listen for changes across different tabs/windows
    window.addEventListener("storage", (event) => {
      if (event.key === "companyId") {
        setCompanyId(event.newValue);
      }
    });

    // Initial state sync in case of changes
    updateCompanyIdState();

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("companyIdChange", updateCompanyIdState);
    };
  }, []);

  return (
    <CompanyContext.Provider value={{ companyId }}>
      {children}
    </CompanyContext.Provider>
  );
};


export const setCompanyId = (newCompanyId) => {
    localStorage.setItem("companyId", newCompanyId);

    // Dispatch a custom event to notify any listeners
    const event = new Event("companyIdChange");
    window.dispatchEvent(event);
};
