import DefaultWrapper from "../../components/defaultWrapper";
import CustomInput from "../../components/miniComponents/customInput";

import { useState, useEffect, useRef } from "react";
import { getCompany, getCompanyDetails, handleUpload, setCompanyDetails, uploadImageToFolder } from "../../firebase";
import { useTranslation } from "react-i18next";




export default function EditCompanyPage() {
    const { t } = useTranslation();
    const [company, setCompany] = useState({
        name: "",
        description: "",
        logo: "",
        address: "",
        info: {
            a: "",
            b: "",
            c: "",
            d: ""
        }
    });
    const inputImageRef = useRef(null);

    const getCompanyDetailsFromDb = async (companyId) => {
        const company = await getCompanyDetails();
        setCompany(company);
    }

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');
        getCompanyDetailsFromDb()
    }, []);

    useEffect(() => {
        if (company.name === "" && company.description === "" && company.logo === "" && company.address === "" && company.info.a === "" && company.info.b === "" && company.info.c === "" && company.info.d === "") return;
        setCompanyDetails(company);
    }, [company]);

    const uploadImage = async (e) => {


        /*const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('upload_preset', 'kompaniLogo');
        data.append('cloud_name', 'kompani');*/

        const file = e.target.files[0];
        const folderName = 'company';
        const fileName = company.id + ".png";


        const link = await handleUpload(file, folderName, fileName)
        console.log(link);
        setCompany({ ...company, logo: link });
    }

    return (
        <DefaultWrapper lagring={true}>
            <div className="content-ny column">
                <h2>{t("Edit Company", "Rediger bedrift")}</h2>
                <div className="column small-gap">
                    <h3>{t("Company name", "Bedriftsnavn")}</h3>
                    <CustomInput label="Bedriftsnavn" value={company.name} onChange={(e) => setCompany({ ...company, name: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Company description", "Bedriftsbeskrivelse")}</h3>
                    <CustomInput label="Bedriftsbeskrivelse" value={company.description} onChange={(e) => setCompany({ ...company, description: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Company logo", "Bedriftslogo")}</h3>
                    <img src={company.logo} alt="logo" style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "contain"
                    }} />
                    <button className="button" onClick={() => inputImageRef.current.click()}>{t("Upload Image", "Last opp bilde")}</button>
                    <input type="file" onChange={uploadImage} ref={inputImageRef} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Company Address", "Bedriftsadresse")}</h3>
                    <CustomInput label={t("Company Address", "Bedriftsadresse")} value={company.address} onChange={(e) => setCompany({ ...company, address: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Company Info", "Bedrifts-informasjon")}</h3>
                    <CustomInput label={t("Company Information", "Bedrifts-informasjon")} value={company.info.a} onChange={(e) => setCompany({ ...company, info: { ...company.info, a: e.target.value } })} />
                    <CustomInput label={t("Company Information", "Bedrifts-informasjon")} value={company.info.b} onChange={(e) => setCompany({ ...company, info: { ...company.info, b: e.target.value } })} />
                    <CustomInput label={t("Company Information", "Bedrifts-informasjon")} value={company.info.c} onChange={(e) => setCompany({ ...company, info: { ...company.info, c: e.target.value } })} />
                    <CustomInput label={t("Company Information", "Bedrifts-informasjon")} value={company.info.d} onChange={(e) => setCompany({ ...company, info: { ...company.info, d: e.target.value } })} />
                </div>
            </div>
        </DefaultWrapper>
    )

}