import { IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";



export default function LoadingWhileEmpty(props) {
    const [displayMessage, setDisplayMessage] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        console.log(props.children)
    }, [props.children])

    useEffect(() => {
        // Set timer for 10 seconds, if nothing is found, display message
        const timer = setTimeout(() => {
            setDisplayMessage(true);
        }, 10000);
        return () => {
            clearTimeout(timer)
        };
    }, []);

    return (
        <div className={"column " + (props?.buttonList ? 'button-list' : '')} >
            {(props?.children?.length || 0) <= 0 &&
                <div className="column center" style={{ paddingTop: 40 }}>
                    {(displayMessage || props.gottenInformation) &&
                        <div className="column center">
                            <h2 className="orange">{t("No Data found", "Ingen data funnet")}. </h2>
                            <h3>{t("Check if you have internet access", "Sjekk om du har internett tilgang hvis det skulle være informasjon her")}.</h3>
                        </div>
                    }

                    {!displayMessage || !props.gottenInformation && <IonSpinner style={{ width: 200, height: 200 }} name="crescent"></IonSpinner>}
                </div>
            }
            {props.children}
        </div>
    )
}