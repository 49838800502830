import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CircleIcon from './circleIcon';
import { UserContext } from '../../App';
import { checkRolesForPermission } from '../utils';


export default function ButtonBigButton(props) {
    const history = useHistory();
    const { userContext } = useContext(UserContext);

    function navigateToNewPage(path) {
        history.push(path);
    }
    console.log(props?.permissions)
    console.log(userContext?.companyUser?.permissions)
    const [show, setShow] = useState(false);

    useEffect(() => {
        console.log(props?.permissions)
        console.log(userContext?.companyUser?.permissions)
        if (props?.permissions !== undefined && !checkRolesForPermission(userContext?.companyUser?.permissions, props?.permissions)) return setShow(false);
        setShow(true);
    }, [userContext?.companyUser?.permissions])

    if (!show) return null;

    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                if (props?.onPress) props?.onPress(e);
                if (props?.onClick) props?.onClick(e);
                if (props.navigateTo) navigateToNewPage(props.navigateTo);
            }}
            className='no-style-button padding small-gap column stretch-width' style={{height: 'auto', maxHeight: 120, background: 'none', border: 'none', boxShadow: 'none'}}
        >
            {props?.icon && <CircleIcon icon={props.icon}></CircleIcon>}
            {props?.image && <img src={props.image} alt=''></img>}

            <div className={'column center small-gap stretch-width stretch-height' + (props.centerText ? "center" : "")}>
                {props?.title && <h2 className={''}>{props.title}</h2>}
                {props?.description && <p>{props.description}</p>}
                {props?.children && props.children}
            </div>

        </button>
    );
}
