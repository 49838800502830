import { useState } from "react";
import { useHistory } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import { SimplifiedTimeCard } from "../../../components/timetracking/timeCards";
import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { getCompanyId, updateTimesInFirebase, uploadTimeToDb } from "../../../firebase";
import { useTranslation } from "react-i18next";



export default function WeekGodkjenning() {
    const { t } = useTranslation();
    const history = useHistory();

    const [timesInWeek, setTimesInWeek] = useState(history.location.state?.week?.times || []);
    console.log(timesInWeek);
    console.log(history.location.state);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const sendToGodkjenning = () => {
        const newTimesInWeek = timesInWeek.map(time => {
            return {
                ...time,
                status: "godkjent"
            }
        })
        console.log(newTimesInWeek);
        console.log(timesInWeek);


        updateTimesInFirebase(newTimesInWeek, getCompanyId(), history.location.state?.week?.weekNumber);
        
    }





    return (
        <DefaultWrapper extraButton={
            <button onClick={() => {
                sendToGodkjenning();
                history.goBack();
            }}>{t("Time Confirmation", "Godkjenn Uke")}</button>
        }>
            <div className="content-ny">
                <h1>{t("Week", "Uke")} {history.location.state?.week?.weekNumber}</h1>
                {timesInWeek.map((time, index) => {
                    console.log(time);
                    return (
                        <SimplifiedTimeCard key={index} time={time} onClick={() => {
                            navigateToNewPage("/timeføring/" + time.id, {
                                timeToEdit: {
                                    ...time,
                                    open: true,
                                    startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                    endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                    //tilleg: time.tilleg,
                                }
                            })
                        }
                        } />
                    )
                }
                )}
            </div>
        </DefaultWrapper>
    )
}