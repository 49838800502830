import { IonAlert, IonCheckbox, IonDatetime, IonIcon, IonModal } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { Timestamp } from "firebase/firestore";

import { trash } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import DefaultWrapper from '../components/defaultWrapper';
import { CheckIfOnPC, ReportContextCreation } from '../App';
import CustomInput from '../components/miniComponents/customInput';
import CustomNumberInput from '../components/miniComponents/customNumberInput';
import TimePickerCarousel from '../components/miniComponents/calendarInput';
import CustomTextareaInput from '../components/miniComponents/customTextareaInput';
import { produce, current } from 'immer';
import { func } from 'prop-types';
import WrapperForTyping from './WrapperForTyping';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';




export default function ReportInforing() {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const { reportContext, setReportContext } = useContext(ReportContextCreation);
    const [deleteAlert, setDeleteAlert] = useState(false);
    //const [oppgave, setOppgave] = useState(reportContext?.oppgaver?.find(oppgave => oppgave.id === params.id));
    const [inputToFocus, setInputToFocus] = useState(null);


    const [oppgave, setOppgave] = useState(() => getOppgaveById(reportContext, params.task));
    const [title, setTitle] = useState(oppgave?.title || `Oppgave ${oppgave?.title.split(' ')[1]}`);

    useEffect(() => {
        setOppgave(getOppgaveById(reportContext, params.task));
    }, [reportContext, params.task]);


    if (!oppgave) return (<DefaultWrapper />)
    return (
        <WrapperForTyping skipBack={true} onBack={() => {
            console.log("oppgave", reportContext);
            //setOppgave(reportContext?.oppgaver?.find(oppgave => oppgave.id === params.id));
            // Set Title in reportContext
            setReportContext(prevContext => ({
                ...prevContext,
                lastUpdated: Timestamp.now(),
                oppgaver: prevContext.oppgaver.map(oppgaveItem =>
                    oppgaveItem.id === params.task
                        ? { ...oppgaveItem, title: title }
                        : oppgaveItem
                )
            }));




            /*setReportContext(prevContext => ({
                ...prevContext,
                oppgaver: prevContext.oppgaver.map(oppgaveItem =>
                    oppgaveItem.id === params.id
                        ? { ...oppgaveItem, title: title }
                        : oppgaveItem
                )
            }));*/
            console.log("oppgave", reportContext.oppgaver.find(oppgave => oppgave.id === params.task));

            history.goBack({ updateRapport: true })
        }} extraButton={<button onClick={() => {
            setDeleteAlert(true);
        }}>{t("Delete Task", "Slett Oppgave")}</button>}>
            <div className='content-ny'>
                <CustomInput type="text" placeholder="Tittel" value={title}
                    ref={inputToFocus}
                    onFocus={() => {
                        let oppgaveNumber = oppgave?.title.split(' ')[1];
                        console.log(oppgaveNumber);


                        if (title === `Oppgave ${oppgaveNumber}`) {
                            setTitle((old) => {
                                return ``;
                            }
                            )
                        }
                    }}
                    onChange={e => setTitle(e.target.value)}
                    onBlur={() => {
                        let oppgaveNumber = oppgave?.title.split(' ')[1];
                        console.log(oppgaveNumber);
                        if (title === "") {
                            setTitle(`Oppgave ${oppgaveNumber}`);
                        }
                    }}
                />

                {oppgave.oppgaveEmner.map(oppgaveEmne => (
                    <OppgaveEmne key={oppgaveEmne.id + 'g'} reportContext={reportContext} setReportContext={setReportContext} oppgave={oppgave} oppgaveEmneFromOppgave={oppgaveEmne} params={params} />
                ))}
            </div>

            <IonAlert
                isOpen={deleteAlert}
                header={t("Delete Task", "Slette Oppgave")}
                message={t("Are you sure you want to delete this task?", "Er du sikker på at du vil slette denne Oppgaven? Dette kan ikke angres.")}
                trigger="present-alert"
                buttons={[
                    {
                        text: t("Cancel", "'Avbryt'"),
                        role: 'cancel',
                        handler: () => {
                            console.log('Alert canceled');
                            setDeleteAlert(false);
                        },
                    },
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: async () => {
                            console.log("oppgave", reportContext);
                            // Delete itself from reportContext
                            setReportContext(prevContext => ({
                                ...prevContext,
                                lastUpdated: Timestamp.now(),
                                oppgaver: prevContext.oppgaver.filter(oppgaveItem => oppgaveItem.id !== params.task)
                            }));

                            history.goBack({ updateRapport: true })
                        },
                    },
                ]}
                onDidPresent={() => console.log('did present')}
                onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
            ></IonAlert>
        </WrapperForTyping>
    )
}


function Task({ reportContext, setReportContext, oppgave, oppgaveEmne, underAlignment, task, isFelles, params }) {
    const [taskValue, setTaskValue] = useState(task.value);

    useEffect(() => {
        setTaskValue(task.value);
    }, [task.value]);

    const updateTaskValue = (value) => {
        setTaskValue(value);
        updateContext({ reportContext, setReportContext, oppgave, oppgaveEmne, underAlignment, task, value, isFelles, params });
    }
    //<div className='column no-gap input-holder' style={{ maxWidth: "100%" }}>

    return (

        <CheckWhatInput task={task} taskValue={taskValue} updateTaskValue={updateTaskValue} params={params} label={task.name} />

    )
}

function OppgaveEmne({ reportContext, setReportContext, oppgave, oppgaveEmneFromOppgave, params }) {
    const { t } = useTranslation();
    const [oppgaveEmne, setOppgaveEmne] = useState(oppgaveEmneFromOppgave);

    useEffect(() => {
        updateContextWithOppgaveEmne({ reportContext, setReportContext, oppgave, oppgaveEmne, params });
    }, [oppgaveEmne]);

    /*useEffect(() => {
        setOppgaveEmne(oppgaveEmneFromOppgave);
    }, [oppgaveEmneFromOppgave]);*/

    const addUnderAlignment = () => {
        // Fetching tasks from the context based on conditions
        let tasks;
        if (!oppgaveEmne.isFelles) {
            const malOppgave = reportContext.mal.oppgaver.find(oppgave => oppgave.id === oppgaveEmne.type);
            tasks = malOppgave.underAlignments;
        } else {
            const malOppgave = reportContext.mal.felles.find(oppgave => oppgave.id === oppgaveEmne.type);
            tasks = malOppgave.underAlignments;
        }

        // Creating a new under alignment
        const newUnderAlignment = {
            id: Math.random().toString(36).substring(7),
            tasks: [...tasks]
        };

        // Updating state and context
        let copyOfoppgaveEmneFromOppgave = { ...oppgaveEmneFromOppgave };
        let copyOfoppgaveEmneFromOppgaveUnderAlignments = [...copyOfoppgaveEmneFromOppgave.underAlignments];
        copyOfoppgaveEmneFromOppgaveUnderAlignments.push(newUnderAlignment);
        copyOfoppgaveEmneFromOppgave.underAlignments = copyOfoppgaveEmneFromOppgaveUnderAlignments;


        setOppgaveEmne(old => {
            return copyOfoppgaveEmneFromOppgave
        });
    };





    return (
        <div className='column wrap' style={{ maxWidth: "100%", width: 'calc(50%-10px)' }}>
            <div className='row center-column' style={{ maxWidth: "100%" }}>
                <div className='divider divider-orange' />
                <h3>{oppgaveEmne.title}</h3>
                <div className='divider divider-orange' />
            </div>
            {oppgaveEmne.underAlignments.map((underAlignment, index) => (
                <div className='column'>
                    {index !== 0 &&
                        <div className='row center'>
                            <h4>{index + 1}.</h4>
                            <div className='divider' />
                        </div>
                    }
                    <div key={underAlignment.id} className={'row wrap'} style={{ maxWidth: "100%", }}>
                        {underAlignment.tasks.map(task => (
                            <Task key={task.id} reportContext={reportContext} setReportContext={setReportContext} oppgave={oppgave} oppgaveEmne={oppgaveEmne} underAlignment={underAlignment} task={task} params={params} />
                        ))}
                    </div>
                </div>
            ))}
            <div className='row flexApart'>
                <button className='delete-last-button' onClick={() => {
                    let copyOfoppgaveEmneFromOppgave = { ...oppgaveEmneFromOppgave };
                    let copyOfoppgaveEmneFromOppgaveUnderAlignments = [...copyOfoppgaveEmneFromOppgave.underAlignments];
                    copyOfoppgaveEmneFromOppgaveUnderAlignments.pop();
                    copyOfoppgaveEmneFromOppgave.underAlignments = copyOfoppgaveEmneFromOppgaveUnderAlignments;
                    setOppgaveEmne(old => {
                        return copyOfoppgaveEmneFromOppgave
                    }
                    );
                }}>{t("Delete Last", "Slett Siste")} <Delete /></button>

                {oppgaveEmne.kanHaFlere && <button className='iconButton' onClick={() => { addUnderAlignment() }}>{t("Add", "Legg til")} +</button>}
            </div>
        </div>
    )
}


function updateContext({ reportContext, setReportContext, oppgave, oppgaveEmne, underAlignment, task, value, isFelles, params }) {
    setReportContext(prevContext => produce(prevContext, draft => {
        draft.lastUpdated = Timestamp.now();
        const oppgave = draft.oppgaver.find(oppgave => oppgave.id === params.task);
        if (!oppgave) return;

        const oppgaveEmneItem = oppgave[isFelles ? 'felles' : 'oppgaveEmner'].find(item => item.id === oppgaveEmne.id);
        if (!oppgaveEmneItem) return;

        const underAlignmentItem = oppgaveEmneItem.underAlignments.find(item => item.id === underAlignment.id);
        if (!underAlignmentItem) return;

        const taskItem = underAlignmentItem.tasks.find(item => item.id === task.id);
        if (!taskItem) return;

        taskItem.value = value;
    }));
}

const updateContextWithOppgaveEmne = ({ reportContext, setReportContext, oppgave, oppgaveEmne, params }) => {
    setReportContext(prevContext => produce(prevContext, draft => {
        draft.lastUpdated = Timestamp.now();
        const oppgaveIndex = draft.oppgaver.findIndex(oppgaveItem => oppgaveItem.id === oppgave.id);
        if (oppgaveIndex === -1) return;

        const oppgaveEmneIndex = draft.oppgaver[oppgaveIndex].oppgaveEmner.findIndex(item => item.id === oppgaveEmne.id);
        if (oppgaveEmneIndex === -1) return;

        console.log("oppgaveEmne", oppgaveEmne, reportContext.oppgaver[oppgaveIndex].oppgaveEmner[oppgaveEmneIndex]);

        draft.oppgaver[oppgaveIndex].oppgaveEmner[oppgaveEmneIndex] = { ...oppgaveEmne };
    }));
}


const getOppgaveById = (reportContext, id) => {
    return reportContext?.oppgaver?.find(oppgave => oppgave.id === id) || null;
};







const CheckWhatInput = React.memo(function CheckWhatInput({ task, taskValue, updateTaskValue, label }) {
    const taskType = task.type.value;

    if (taskType === "checkbox") return <IonCheckbox checked={taskValue} onIonChange={e => updateTaskValue(e.detail.checked)} label={label} />
    if (taskType === "date") return <CustomInput type="date" noIcon={true} value={taskValue} onChange={e => updateTaskValue(e.target.value)} label={label} />
    //<TimePickerCarousel initialDate={taskValue} onChange={updateTaskValue} />
    if (taskType === "number") return <CustomNumberInput outerStyle={{ width: 'calc(50% - 10px)' }} key={task.id} onChange={e => updateTaskValue(e.target.value)} value={taskValue || 0} label={label} />

    //if (taskType === "number") return <CustomNumberInput key={task.id} onChange={e => updateTaskValue(e.target.value)} value={task.value || 0} />
    if (taskType === "text") return <CustomInput key={task.id} onChange={e => updateTaskValue(e.target.value)} value={taskValue || ""} label={label} />
    //if (taskType === "text") return <CustomInput onChange={e => updateTaskValue(e.target.value)} value={task.value || ""} />
    //if (taskType === "textarea") return <textarea className='stretch-width' onChange={e => updateTaskValue(e.target.value)} value={task.value || ""} />
    if (taskType === "textarea") return <CustomTextareaInput key={task.id} onChange={e => updateTaskValue(e.target.value)} value={taskValue || ""} label={label} />
});



