import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DefaultWrapper from '../../../../components/defaultWrapper';
import CustomInput from '../../../../components/miniComponents/customInput';
import { Add, Delete } from '@mui/icons-material';
import { SaveFirmSettings, createId, getFirmSettings, setCompanyTimeSettings } from '../../../../firebase';
import CircleIconButton from '../../../../components/miniComponents/circleIconButton';


export default function Tillegg() {
    const { t } = useTranslation();
    const history = useHistory();
    
    const [settings, setSettings] = useState({
        tillegg: [],
        ...history?.location?.state.settings
    });

    useEffect(() => {
        const getSettings = async () => {
            const onUpdate = (data) => {
                console.log(data);
                setSettings({ ...settings, ...data })
            }
            const firmSettings = await getFirmSettings("timeforingsInstillinger", onUpdate)
            setSettings(old => {
                return { ...old, ...firmSettings }
            });
        }
        getSettings();
    }, [])



    const updateSettings = async () => {
        console.log(settings);
        await SaveFirmSettings(settings, "timeforingsInstillinger");
    }

    return (
        <DefaultWrapper lagring={true} onBack={async () => {
            await updateSettings()
        }}>
            <div className="content-ny">
                <div className='column'>
                    <h1>{t("Additions", "Tillegg")}</h1>
                    {settings.tillegg.map((tillegg) => {
                        return (
                            <div className='row center' key={tillegg.id}>
                                <CustomInput label={t("Name", "Navn")} value={tillegg.navn} onChange={(e) => {
                                    let settingsCopy = { ...settings };
                                    settingsCopy.tillegg[settingsCopy.tillegg.indexOf(tillegg)].navn = e.target.value;
                                    setSettings(settingsCopy);
                                }}></CustomInput>
                                <CircleIconButton icon={Delete}
                                    onClick={() => {
                                        let settingsCopy = { ...settings };
                                        settingsCopy.tillegg = settingsCopy.tillegg.filter((value) => value.id !== tillegg.id);
                                        setSettings(settingsCopy);
                                    }
                                    } />
                            </div>
                        )
                    })
                    }
                    <button onClick={() => {
                        let settingsCopy = { ...settings };
                        settingsCopy.tillegg.push({ id: createId(), navn: "" });
                        setSettings(settingsCopy);
                    }}>{t("New addition", "Nytt tillegg")}<Add /></button>
                </div>
            </div>
        </DefaultWrapper>
    )
}