import UtstyrCard from "../components/utstyrCard"
import { IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonToolbar, LocationHistory } from "@ionic/react"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { searchObjects } from "../components/search"
//import { getUtstyr } from "../firebase/utstyr"
import { add } from "ionicons/icons"
import { getUtstyr } from "../firebase"
import DefaultWrapper from "../components/defaultWrapper"
import { animated, useTrail } from "@react-spring/web"
import LoadingWhileEmpty from "../components/miniComponents/loadingWhileEmpty"
import { CheckIfOnPC } from "../App"
import { useTranslation } from "react-i18next"
import { Add } from "@mui/icons-material"

export default function UtstyrsListe(props) {
    const { t } = useTranslation()
    const location = useLocation()
    const information = location.state

    const [utstyr, setUtstyr] = useState([])
    const [filteredUtstyr, setFilteredUtstyr] = useState([])
    const [search, setSearch] = useState('')

    const history = useHistory()

    //filter utstyr on search change
    useEffect(() => {
        console.log(utstyr)
        //searchObjects(utstyr, search, setFilteredUtstyr)
        const filteredList = searchObjects(utstyr, search, setFilteredUtstyr)
        console.log(filteredList)
        setFilteredUtstyr(filteredList)
    }, [search, utstyr])




    // Get all reports from firebase with the project name
    useEffect(() => {
        const onUpdate = (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
            setUtstyr(data)
        }

        async function getUtstyrFromFirebase() {
            const data = await getUtstyr(onUpdate)
            setUtstyr(data)
        }
        getUtstyrFromFirebase()

    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });

    }

    useEffect(() => {
        console.log(filteredUtstyr)
    }, [filteredUtstyr])

    const trail = useTrail(filteredUtstyr.length, {
        from: { opacity: 0, transform: 'translate3d(0, -40px, 0)' },
        to: { opacity: 1, transform: 'translate3d(0, 0px, 0)' },
    })



    return (
        <DefaultWrapper itemsToFilter={utstyr} søkefelt={true} updateFilteredItems={(e) => setFilteredUtstyr(e)} noBackButton={CheckIfOnPC()}>
            <div className='content-ny column'>
                <div className='flexApart row'>
                    <h1>{t("Equipment List", "Utstyrsliste")}</h1>
                </div>

                <div className="column">
                    <LoadingWhileEmpty>
                        {trail.map((style, index) => {
                            return (
                                <animated.div key={filteredUtstyr[index].id} style={style}>
                                    <UtstyrCard key={filteredUtstyr[index].id} utstyr={filteredUtstyr[index]} onPress={
                                        () => {
                                            navigateToNewPage(`/utstyr/${filteredUtstyr[index].id}`, filteredUtstyr[index])
                                        }
                                    } />
                                </animated.div>
                            )
                        })}
                    </LoadingWhileEmpty>
                </div>
            </div>

            <button className="btn btn-primary bottom-right-button"
                onClick={() => {
                    history.push({
                        pathname: '/utstyr',
                        state: {
                            id: randomId(),
                            navn: "",
                            bilde: "",
                            utstyrsNummer: "",
                            kjøpt: "",
                        }
                    })
                }}
            >{t("New Equipment", "Nytt Utstyr")} <Add /> </button>
        </DefaultWrapper>
    );
}

function randomId() {
    return Math.random().toString(36).substr(2, 9);
}