import { useEffect, useState } from "react";
import { getCompanyUsers, getRoles, getUtstyr } from "../../firebase";
import { SearchWithPopup } from "../oppgaver/searchWithPopup";
import { useTranslation } from 'react-i18next';




export default function PeopleAndRolesSearchWithPopup({ onChange, addedRoles=[], addedPeople=[] }) {
    const { t } = useTranslation();
    const [peopleInCompany, setPeopleInCompany] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesAndPeople, setRolesAndPeople] = useState([]);

    const [chosenPeople, setChosenPeople] = useState([...addedPeople]);
    const [chosenRoles, setChosenRoles] = useState([...addedRoles]);
    const [chosenRolesAndPeople, setChosenRolesAndPeople] = useState([]);

    useEffect(() => {
        const onUpdate = (people) => {
            const peopleDefined = people.map((person) => {
                return {
                    ...person,
                    navn: person.navn,
                    type: "person",
                    //textColor: "white",
                }
            });
            setPeopleInCompany(peopleDefined);
        }

        const onUpdateRoles = (roles) => {
            const rolesDefined = roles.map((role) => {
                return {
                    ...role,
                    navn: role.navn,
                    type: "role",
                    //textColor: "orange",
                }
            });
            setRoles([...peopleInCompany, ...rolesDefined]);
        }

        getRoles(onUpdateRoles);

        getCompanyUsers(onUpdate);
    }, []);

    useEffect(() => {
        console.log({ people: chosenPeople, roles: chosenRoles })
        onChange({ people: chosenPeople, roles: chosenRoles })
    }, [chosenPeople, chosenRoles])

    useEffect(() => {
        // set roles and people, do not include chosen people and roles
        setRolesAndPeople([...roles, ...peopleInCompany].filter(item => {
            if (item.type === "role") {
                return !chosenRoles.some(chosenRole => chosenRole.id === item.id)
            } else {
                return !chosenPeople.some(chosenPerson => chosenPerson.id === item.id)
            }
        }))
    }, [roles, peopleInCompany, chosenPeople, chosenRoles])

    useEffect(() => {
        console.log(chosenRolesAndPeople)
        setChosenRolesAndPeople([...chosenRoles, ...chosenPeople])
    }, [chosenRoles, chosenPeople])

    return (
        <SearchWithPopup selected={chosenRolesAndPeople} items={rolesAndPeople} onChoise={
            (item) => {
                console.log(item)
                if (item.type === "role") {
                    setChosenRoles([...chosenRoles, item])
                }
                if (item.type === "person") {
                    setChosenPeople([...chosenPeople, item])
                }
            }
        }
            onRemove={(item) => {
                if (item.type === "role") {
                    setChosenRoles(chosenRoles.filter(role => role.id !== item.id))
                }
                if (item.type === "person") {
                    setChosenPeople(chosenPeople.filter(person => person.id !== item.id))
                }
            }}
        />
    )
}