import React, { useCallback, useEffect, useState } from 'react';
import CustomDropdown from './miniComponents/customDropdown';
import { useTranslation } from 'react-i18next';

function DateRangeSelector({ onChange }) {
  const { t } = useTranslation();
  const [active, setActive] = useState(localStorage.getItem('activeDateRange') || 'This Week');

  useEffect(() => {
    handleClick(active)();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDateRange = (range) => {
    const now = new Date();
    switch (range) {
      case 'Denne Uken': {
        const dayOfWeek = now.getDay();
        const startDate = new Date(now);
        startDate.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);

        return { startDate, endDate };
      }
      case 'Forrige Uke': {
        const dayOfWeek = now.getDay();
        const startDate = new Date(now);
        startDate.setDate(now.getDate() - dayOfWeek - 6);
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);

        return { startDate, endDate };
      }
      case 'Denne Måneden': {
        const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);

        return { startDate, endDate };
      }
      case 'Forrige Måned': {
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);

        return { startDate, endDate };
      }
      default:
        const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);

        return { startDate, endDate };
    }
  };

  const handleClick = useCallback((range) => () => {
    console.log(range);
    const { startDate, endDate } = getDateRange(range);
    setActive(range);
    localStorage.setItem('activeDateRange', range);
    if (onChange) {
      onChange({ startDate, endDate });
    }
  }, [onChange]);



  return (
    <select value={active} onChange={(e) => handleClick(e.target.value)()}>
      <option value="Denne Uken">{t("This Week", "Denne Uken")}</option>
      <option value="Forrige Uke">{t("Last Week", "Forrige Uke")}</option>
      <option value="Denne Måneden">{t("This Month", "Denne Måneden")}</option>
      <option value="Forrige Måned">{t("Last Month","Forrige Måned")}</option>
    </select>
    
  );
}

export default DateRangeSelector;
