import { useTranslation } from "react-i18next";
import DefaultWrapper from "../../components/defaultWrapper";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";

import { getAuthUser, getTimeListForUser } from "../../firebase"
import TimelisteCard from "../../components/timetracking/timelisteCard"
import { createDateOutOfFirebaseTimestamp } from "../../pages/timeføring/timeføring"
import { navigate } from "ionicons/icons"
import { useHistory } from "react-router"


export default function TimeOff() {
    const { t } = useTranslation();
    const { user } = useContext(UserContext)
    const [settings, setSettings] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        reason: "",
        status: "pending",
        comments: [],
        user: user
    });

    const [requests, setRequests] = useState([
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "declined",
            comments: []
        },
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "approved",
            comments: []
        },
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "pending",
            comments: []
        }


    ]);

    const send = () => {

    }

    const history = useHistory()
    const [timeList, setTimeList] = useState([])
    const [sortedTimeList, setSortedTimeList] = useState([[]])

    useEffect(() => {
        //Sort timeList by date
        //Create a new array for each date
        //Add each time to the correct array
        //Set sortedTimeList to the new array
        let sorted = [[]]
        let lastDate = ""
        timeList.forEach((time) => {
            const date = createDateOutOfFirebaseTimestamp(time.dato)
            if (date !== lastDate) {
                sorted.push([])
                lastDate = date
            }
            sorted[sorted.length - 1].push(time)
        })
        setSortedTimeList(sorted)
    }, [timeList])

    useEffect(() => {
        const onUpdate = (querySnapshot) => {
            console.log(querySnapshot)
            setTimeList(querySnapshot.reverse())
        }

        async function startGetTimeListForUser() {

            const userUID = await getAuthUser()
            const uid = userUID.uid
            console.log(userUID)
            getTimeListForUser(onUpdate, uid)
        }
        startGetTimeListForUser()
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    return (
        <DefaultWrapper>
            <div className="content-ny">
                <h1>{t("Time off", "Avspasering")}</h1>

                <div className="column content-ny">
                    {timeList.map((time) => (
                        <TimelisteCard
                            time={time}
                            key={time.id}
                            addresse={`${time?.place?.addresse}, ${time?.place?.postnummer}`}
                            date={createDateOutOfFirebaseTimestamp(time.dato)}
                            timeStart={createDateOutOfFirebaseTimestamp(time.startDate)}
                            timeEnd={createDateOutOfFirebaseTimestamp(time.endDate)}
                            kommentar={time.kommentar}
                            prosjekt={time.prosjekt}
                            onClick={() => {
                                navigateToNewPage(`/arbeidsplan/${time.id}`, time)
                            }}
                        />
                    ))}
                </div>

            </div>

        </DefaultWrapper>
    )
}

