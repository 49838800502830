import ItemDropdownButtons from "../../components/miniComponents/ItemDropdownButtons";

import { IonPage } from '@ionic/react';
import GoogleButton from 'react-google-button';
import { useHistory, useLocation } from 'react-router';
import { checkIfAuthenticated, athentication, signInWithEmailAndPassword, signInWithEmailAndPasswordInFirebase, signUpWithEmailAndPassword, acceptInvitation } from '../../firebase';
import DefaultWrapper from '../../components/defaultWrapper';
import { useContext, useEffect, useRef, useState } from 'react';
import CustomInput from '../../components/miniComponents/customInput';
import logo from '../../assets/logo.png';
import ButtonListButton from '../../components/miniComponents/buttonListButton';
import { ReactComponent as addIcon } from '../../assets/icons/material-symbols_add.svg';
import { UserContext } from "../../App";
import { useTranslation } from "react-i18next";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { fetchSignInMethodsForEmail, getAuth, GoogleAuthProvider, signInWithCredential } from "@firebase/auth";



export default function RegisterUser() {
    const { t } = useTranslation();
    const { userContext, setUserContext } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [signUpMessage, setSignUpMessage] = useState('');
    const [alreadySignedUp, setAlreadySignedUp] = useState(false);
    const history = useHistory();
    const formRef = useRef();
    const submitRef = useRef();
    const appleSignInRef = useRef();



    const [newUser, setNewUser] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        name: '',
    });

    const authEndFunction = async () => {
        console.log("Auth end function called");
        const invId = history?.location?.state?.invId;


        await acceptInvitation(userContext?.user?.id, { id: invId })
        navigateToNewPage('/company');
    };

    const handleEmailSignUp = async () => {
        if (newUser.password !== newUser.passwordConfirm) {
            setSignUpMessage(t("Password isn't the matching", "Passordene er ikke like"));
            return;
        }
        if (newUser.password.length < 6) {
            setSignUpMessage(t("The password must be at least 6 letters", "Passordet må være minst 6 tegn"));
            return;
        }
        if (newUser.name.length < 3) {
            setSignUpMessage(t("The name must be at least 3 letters", "Navnet må være minst 3 tegn"));
            return;
        }
        if (newUser.email.length < 3) {
            setSignUpMessage(t("The Email must be at least 3 letters", "Eposten må være minst 3 tegn"));
            return;
        }
        if (!newUser.email.includes('@')) {
            setSignUpMessage(t("The Email must have a @", "Eposten må inneholde @"));
            return;
        }

        if (alreadySignedUp) {
            setSignUpMessage(t("There is already a user with this email", "Du har allerede opprettet en bruker"));
            return;
        }

        const checkIfalreadySignedUp = async () => {
            const isSignedUp = await fetchSignInMethodsForEmail(getAuth(), newUser.email);
            console.log("isSignedUp", isSignedUp);
            if (isSignedUp.length > 0) {
                setAlreadySignedUp(true);
                return true;
            }
        }
        console.log("checkIfalreadySignedUp", await checkIfalreadySignedUp());
        if (await checkIfalreadySignedUp()) {
            // An account with this email already exists. Please sign in or reset your password.
            return setSignUpMessage(t(
                "An account with this email already exists. Please sign in or reset your password.",
                "Det er allerede en bruker med denne eposten. Vennligst logg in eller reset passordet ditt."
            ));
        }

        try {

            await signUpWithEmailAndPassword(newUser.email, newUser.password, newUser.name);
            authEndFunction();
            setSignUpMessage(t("An email is sendt to the email", "En epost er sendt til deg for å bekrefte brukeren din"))
        } catch (error) {
            setMessage(error.message);
        }
    };

    const handleLoginApple = async (e) => {
        console.log("Login")
        const signInWithApple = async () => {
            try {
                // 1. Create credentials on the native layer
                const result = await FirebaseAuthentication.signInWithApple();
                // 2. Sign in on the web layer using the id token
                console.log("Login done")
                const credential = GoogleAuthProvider.credential(result.credential?.idToken);
                const auth = getAuth();
                await signInWithCredential(auth, credential);
                //await FirebaseAuthentication.linkWithGoogle();
                console.log("Login done")

                navigateToNewPage('/company');
            } catch (error) {
                console.error(error);
            }
        }


        signInWithApple();
    };

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const renderAppleSignInButton = () => {
        window.AppleID.auth.renderButton()

        // Add event listener to the Apple Sign-In button

        // clone the button to remove the event listener
        const appleSignInButton = document.getElementById('appleid-signin');
        if (appleSignInButton) {
            var old_element = appleSignInButton;
            var new_element = old_element.cloneNode(true);
            old_element.parentNode.replaceChild(new_element, old_element);
            appleSignInRef.current = new_element;

            new_element.addEventListener('click', handleLoginApple);

        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/no_NO/appleid.auth.js";
        script.async = true;
        script.onload = () => {
            renderAppleSignInButton();
        };
        document.body.appendChild(script);

        return () => {
            // Cleanup: Remove event listener and script
            const appleSignInButton = document.getElementById('appleid-signin');
            if (appleSignInButton) {
                appleSignInButton.removeEventListener('click', handleLoginApple);
            }
            document.body.removeChild(script);
        };
    }, []);



    return (
        <DefaultWrapper>
            <div className='content-ny' style={{ margin: "auto", maxWidth: "1000px" }}>
                <h1>{t("Register a new user", "Registrer Ny Bruker")}</h1>

                <div className='column login-buttons center stretch-width'>
                    <GoogleButton onClick={async (e) => {
                        await athentication(authEndFunction)
                        history.push({
                            pathname: '/chooseCompany',
                        });
                        //window.location.href = '/chooseCompany';
                    }} label={t("Register with google", "Registrer med google")} style={{ borderRadius: 5 }} />
                    <div style={{
                        width: "240px",
                        height: "50px"
                    }} ref={appleSignInRef} data-type="sign-up"
                        id="appleid-signin" className="signin-button" data-color="white" data-border="true"></div>
                </div>
                <form ref={formRef} className='column' onSubmit={(e) => {
                    e.preventDefault();
                    console.log(e.target);
                    //if (e.target === submitRef.current) {
                    console.log('submit');
                    handleEmailSignUp();
                    //}
                }}>
                    <ItemDropdownButtons title='Registrer med Email & Passord' doNotBumpIn={true}
                        items={
                            <div className='column'>
                                <div className='column'>
                                    <CustomInput
                                        type="text"
                                        label={t("Name", "Navn")}
                                        placeholder="Navn"
                                        value={newUser.name}
                                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                                        required
                                    />
                                    <CustomInput
                                        type="email"
                                        label={t("Email", "Epost")}
                                        placeholder="Email"
                                        value={newUser.email}
                                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                        required
                                    />
                                    <CustomInput
                                        type="password"
                                        label={t("Password", "Passord")}
                                        placeholder="Passord"
                                        value={newUser.password}
                                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                        pattern="(?=.*[A-Z]).{6,}"
                                        title="Password must be at least 6 characters long and contain at least one uppercase letter."                              
                                        required
                                    />
                                    <CustomInput
                                        type="password"
                                        label={t("Password confirmation", "Passord bekreftelse")}
                                        placeholder="Passord bekreftelse"
                                        value={newUser.passwordConfirm}
                                        onChange={(e) => setNewUser({ ...newUser, passwordConfirm: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className='row flexApart'>
                                    <div></div>
                                    <input className="button" type="submit" value={t("Register", "Registrer")} ref={submitRef} />
                                </div>

                                <p>{message}</p>
                                <p>{signUpMessage}</p>
                            </div>
                        } />
                </form>
            </div>
        </DefaultWrapper>
    );
}