import { Add, Close, Search } from "@mui/icons-material";
import CircleIcon from "./circleIcon";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";



export default function CustomModal({ items, open, visible, Icon, iconKey, onClose, onChoise, onNew, doNotCloseOnNew, children, noSearch }) {
    const { t } = useTranslation();
    const holder = useRef();
    const modal = useRef();
    const [searchString, setSearchString] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const [show, setShow] = useState(open || visible || false);

    useEffect(() => {
        setShow(open || visible || false);
    }, [open, visible]);

    /*useEffect(() => {
        setFilteredItems(items);
    }, [items]);*/

    useEffect(() => {
        // Could use SearchObjects from search.jsx here, but this is faster
        if (searchString === '') return setFilteredItems(items);
        setFilteredItems(items.filter((item) => item?.navn?.toLowerCase().includes(searchString.toLowerCase()) || item?.name?.toLowerCase().includes(searchString.toLowerCase()) || item?.title?.toLowerCase().includes(searchString.toLowerCase()) || item?.tittel?.toLowerCase().includes(searchString.toLowerCase()) || item?.label?.toLowerCase().includes(searchString.toLowerCase())));
    }, [searchString, items]);

    const handleClose = () => {
        setSearchString('');
        if (onClose) onClose();
        setShow(false);
    }

    const handleClickOutside = (event) => {
        if (modal.current && !modal.current.contains(event.target) && event.target === holder.current) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [holder]);


    if (!show) return null;

    const sortedItems = filteredItems ? filteredItems.sort((a, b) => {
        const aValue = a?.navn || a?.name || a?.title || a?.tittel || a?.label || '';
        const bValue = b?.navn || b?.name || b?.title || b?.tittel || b?.label || '';
        return aValue.localeCompare(bValue);
    }) : [];

    let currentLetter = '';
    const itemsWithDividers = sortedItems.flatMap((item) => {
        const itemValue = item?.navn || item?.name || item?.title || item?.tittel || item?.label || '';
        const icon = Icon  || (iconKey ? Object?.values(item)[0][iconKey] : false) || item?.icon || false
        console.log(Icon, (iconKey ? Object?.values(item)[0][iconKey] : false), item?.icon, false)
        let firstLetter = itemValue[0]?.toLowerCase()
        if (!firstLetter) firstLetter = 'Ingen Navn';

        if (firstLetter !== currentLetter) {
            currentLetter = firstLetter;
            return [<div className="orange">{firstLetter} <div className="division-line" /></div>, (
                <button className="modal-card row center-column stretch-width" onClick={() => {
                    if (onChoise) onChoise(item);
                    handleClose();
                }}>
                    <CircleIcon icon={icon} />
                    <h2>{itemValue}</h2>
                </button>
            )];
        } else {
            return [
                <button className="modal-card row center-column stretch-width" onClick={() => {
                    if (onChoise) onChoise(item);
                    handleClose();
                }}>
                    <CircleIcon icon={icon} />
                    <h2>{itemValue}</h2>
                </button>
            ];
        }
    });

    return (
        <div className={"effektiv-jobb-modal-holder"} ref={holder}>
            <div className="effektiv-jobb-modal column noGap" ref={modal}>
                <div className="modal-header row flexApart">
                    <div />
                    <Close className="close-button" onClick={handleClose} />
                </div>

                <div className="modal-content column padding">
                    {!noSearch && <div className="row stretch-width">

                        <div className="column small-gap modal-search-container stretch-width">
                            <div className="modal-search row small-padding">
                                <input type="text" placeholder="Søk" onChange={(e) => setSearchString(e.target.value)} />
                                <Search />
                            </div>
                        </div>
                        {onNew && <button id="modal-ny" className="ny" onClick={() => {
                            if (onNew) onNew();
                            if (doNotCloseOnNew) return;
                            handleClose();
                        }}>{t("New", "Ny")} <Add /></button>}
                    </div>
                    }

                    <div className="column small-gap">
                        {itemsWithDividers}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}