import { IonContent, IonHeader, IonModal, IonPage, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import { createId, createProject, getClientById, getDocumentsByNoProjectId, getProjects, getProjectsByCompanyId } from "../../../firebase";
import { useEffect, useState } from "react";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import CustomInput from "../../../components/miniComponents/customInput";

import { ReactComponent as WorkIcon } from '../../../assets/icons/material-symbols_work.svg';
import { SearchAddressesWithPopup } from "../../../components/miniComponents/searchAddresses";
import { useTranslation } from "react-i18next";


export default function Projects(props) {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory();
    const [firma, setFirma] = useState({
        navn: "",
        addresse: "",
        postnummer: "",
        by: "",
        orgNummer: "",
        arbeidsplass: "",
        kontaktpersoner: [{ navn: "", email: "" }],
        id: createId(),
        ...history?.location?.state
    })
    const [projects, setProjects] = useState([])

    useEffect(() => {
        //listen to client updates in firebase
        //update client list
        const onUpdate = (querySnapshot) => {
            console.log(querySnapshot)
            setFirma(querySnapshot)
        }
        console.log(history.location.state)
        getClientById(history.location.state.id, onUpdate)
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        const onUpdate = (data) => {
            setProjects(data)
        }

        getProjectsByCompanyId(firma, onUpdate)
    }, [])


    useEffect(() => {
        //Remove spaces from orgnr
        const orgnr = firma?.orgNummer?.replace(/\s/g, '');
        if (!orgnr) return;
        if (orgnr.length !== 9) return;

        // Querying the Enhetsregisteret API
        /*const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
        const response = await axios.get(apiUrl);*/
        // Use fetch instead
        const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
        const apiUrlRoller = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}/roller`;


        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                // If the orgnr is valid, set the client's name to the name of the company
                // If the orgnr is invalid, set the client's name to an empty string
                // If the orgnr is empty, set the client's name to an empty string

                // If the orgnr is valid, set the client's address to the address of the company
                // If the orgnr is invalid, set the client's address to an empty string
                // If the orgnr is empty, set the client's address to an empty string

                // If the orgnr is valid, set the client's postal code to the postal code of the company
                // If the orgnr is invalid, set the client's postal code to an empty string
                // If the orgnr is empty, set the client's postal code to an empty string
                async function fetchPlaces(query) {
                    try {

                        const response = await fetch(`http://localhost:3001/fetchPlaces?input=${query}`);
                        const data = await response.json();
                        return data.candidates[0];  // get the first result
                    } catch (error) {
                        console.error('Error fetching from server:', error);
                        return null;
                    }

                }

                console.log(data)
                async function setData() {
                    let temp = { ...firma, lat: null, lng: null };

                    if (!data || data.status === 400 || data.status === 403) return;

                    if (firma.navn === "") {
                        temp.navn = data?.navn;
                    }
                    if (firma.addresse === "") {

                        temp.addresse = data?.forretningsadresse?.adresse[0]

                        async function fetchPlacesAsync() {
                            const place = await fetchPlaces(data?.forretningsadresse?.adresse[0]);
                            console.log(place);
                            return place;
                        }

                        const addressWithLangAndLat = await fetchPlacesAsync()

                        /*temp.lat = addressWithLangAndLat?.geometry.lat;
                        temp.lng = addressWithLangAndLat?.geometry.lng;*/
                        // add lat and lng to temp
                        console.log(addressWithLangAndLat);
                        temp.lat = addressWithLangAndLat?.geometry.location.lat;
                        temp.lng = addressWithLangAndLat?.geometry.location.lng;
                        console.log(temp);

                    }
                    if (firma.postnummer === "") {
                        temp.postnummer = data?.forretningsadresse?.postnummer
                    }
                    if (firma.by === "") {
                        temp.by = data?.forretningsadresse.poststed
                    }
                    console.log(temp);
                    setFirma(old => {
                        console.log({ ...old, ...temp });
                        return { ...old, ...temp }
                    }
                    );
                }
                setData();
            });
    }, [firma.orgNummer]);


    return (
        <DefaultWrapper>
            <div className="content-ny column big-gap">
                <div className="row flexApart">
                    <h1>{firma?.name || firma?.navn}</h1>
                    <div className="row">
                        <h3>ID</h3>
                        <h3>{firma?.id}</h3>
                    </div>
                </div>

                <h3>{t("Name", "Navn")}</h3>
                <CustomInput type="text" placeholder="Firma / Navn" value={firma?.navn}
                    onChange={(e) => { setFirma({ ...firma, navn: e.target.value }); }}
                />
                <h3>{t("Organization Number", "Organisasjonsnummer")}</h3>
                <CustomInput type="text" placeholder="Organisasjonsnummer" value={firma.orgNummer}
                    onChange={(e) => { setFirma({ ...firma, orgNummer: e.target.value }); }}
                />
                <h3>{t("Address", "Adresse")}</h3>
                <SearchAddressesWithPopup lng={firma?.lng} lat={firma?.lat} value={firma.addresse} onChange={(addresse) => {
                    console.log(addresse)
                    setFirma((old) => {
                        console.log(old)
                        return { ...old, addresse: addresse.addresse, postnummer: addresse.postalCode, by: addresse.city, lat: addresse.lat, lng: addresse.lng }
                    })
                }} />

                <div className='padding'>
                    <div className='flexApart'>
                        <h1>{t("Projects", "Prosjekter")}</h1>
                        <button className='ny' onClick={() => {
                            console.log(history.location.state)
                            navigateToNewPage('/prosjekt/edit', { firma: firma })
                        }}>{t("New", "Ny")} +</button>
                    </div>

                    <div className='downContainer column big-gap button-list'>
                        {projects?.map((projekt) => {
                            return <ButtonListButton title={projekt.navn} description={projekt.addresse + (projekt.postnummer ? ", " + projekt.postnummer : "")}
                                icon={WorkIcon} key={projekt.id} className="project column" onClick={
                                    () => navigateToNewPage(`/prosjekter/${projekt.id}`, { firma: firma, projekt: projekt })
                                } />
                        })}
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    );
}