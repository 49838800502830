import { useEffect, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";
import { getCompanyUsers, getRecordedTimeFromDBbyUserId } from "../../../firebase";
import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { Avatar } from "@mui/material";
import { ArrowRight, SubdirectoryArrowRight, SubdirectoryArrowRightRounded } from "@mui/icons-material";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";




export default function Timegodkjenning() {
    const { t } = useTranslation();
    const [brukere, setBrukere] = useState([]) //Ansatte som har sendt inn timer
    const [usersWithHoursToApprove, setUsersWithHoursToApprove] = useState([]) //Timer som skal godkjennes
    const history = useHistory();

    useEffect(() => {
        getCompanyUsers((users) => {
            setBrukere(users);
            console.log(users);
        })
    }, [])

    useEffect(() => {
        const threeMonthsBack = new Date(new Date().setMonth(new Date().getMonth() - 3));

        const onUpdate = (timer, brukerId) => {
            let timerThatIsSent = [];
            timer.forEach((time) => {
                if (time.status === "sent") {
                    timerThatIsSent.push(time)
                }
            })

            const brukerToFind = brukere.find((bruker) => bruker.id === brukerId)
            const bruker = {
                ...brukerToFind,
                timer: timerThatIsSent
            }
            //const checkIfUserAlreadyExists = usersWithHoursToApprove?.find((user) => user?.id === bruker?.id)
            console.log(timerThatIsSent.length);
            //console.log(checkIfUserAlreadyExists);
            if (timerThatIsSent.length === 0) return
            /*if (!checkIfUserAlreadyExists) {
                setUsersWithHoursToApprove((prevState) => {
                    return [...prevState, bruker]
                })
            } else {*/
            setUsersWithHoursToApprove((prevState) => {
                return [...prevState.filter((user) => user.id !== bruker.id), bruker]
            })
            //}

        }


        const getTimerTilGodkjenning = async () => {
            brukere.forEach(async (bruker) => {
                await getRecordedTimeFromDBbyUserId(bruker.id, threeMonthsBack, new Date(), onUpdate)
            })
        }
        getTimerTilGodkjenning();
    }, [brukere])

    const calculateHoursForUser = (userWithHours) => {
        console.log(userWithHours);
        
        let totalHours = 0;
        const totalMinutes = false;

        userWithHours.timer.forEach((time) => {
            const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
            const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
            const hours = endDate.getHours() - startDate.getHours();
            const minutes = endDate.getMinutes() - startDate.getMinutes();
            // Rounded to closest single decimal, either 5 or 0 (0 doesn't show up)
            totalHours += Math.round((hours + minutes / 60) * 2) / 2;
            totalMinutes = minutes;
        })

        
        if (totalHours === 0) {
            return {value:totalMinutes, suffix: " min"};
        }

        
        return {value:totalHours, suffix:" t"};
    }

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    return (
        <DefaultWrapper header={true}>
            <div className="content-ny">
                <h1>{t("Not yet accepted", "Ikke godkjente timer")}</h1>
                {usersWithHoursToApprove.map((userWithHours) => {
                    const { value, suffix } = calculateHoursForUser(userWithHours);
                    return (
                        <div className="simple-time-card row small-gap" onClick={() => {
                            console.log(userWithHours);
                            //Go to
                            navigateToNewPage("/timegodkjenning/uke", { userId: userWithHours.id })
                        }} >
                            <div className="simple-time-card-time column center">
                                <h1>{value + suffix}</h1>
                            </div>
                            <div className="row stretch-width time-card center">
                                <Avatar src={userWithHours?.bilde || userWithHours?.avatar || userWithHours?.image} />
                                <div className="column stretch-width small-gap no-overflow">
                                    <div className="row small-gap">
                                        <h3>{userWithHours?.navn}</h3>
                                    </div>
                                </div>
                                <SubdirectoryArrowRightRounded />
                            </div>
                        </div>
                    )
                })}
            </div>
        </DefaultWrapper>
    )
}