import { IonInput } from "@ionic/react";
import MathInput from "./mathInput";
import { useEffect, useState } from "react";
import DefaultWrapper from "../defaultWrapper";


export default function KubbikKalkulator() {
    const [inputs, setInputs] = useState({
        bredde: {},
        lengde: {},
        dybde: {},
    });

    const [result, setResult] = useState(0);

    const calculateAmountInCm = (item) => {
        if (!Number(item.value)) return 0;

        if (item.unit === "dm") return Number(item.value) * 10;

        if (item.unit === "m") return Number(item.value) * 100;

        return Number(item.value);
    }


    const calculate = () => {
        let bredde = calculateAmountInCm(inputs.bredde)
        let lengde = calculateAmountInCm(inputs.lengde)
        let dybde = calculateAmountInCm(inputs.dybde)
        console.log(bredde, lengde, dybde)

        let result = bredde * lengde * dybde / 1000000;
        console.log(result)
        return result;
    }

    useEffect(() => {
        setResult(calculate())
    }, [inputs])


    return (
        <div className="column">
            <h1>Kubbik-kalkulator</h1>
            <h3>Bredde</h3>
            <MathInput onChange={(value) => setInputs({ ...inputs, bredde: value })} />
            <h3>Lengde</h3>
            <MathInput onChange={(value) => setInputs({ ...inputs, lengde: value })} />
            <h3>Dybde</h3>
            <MathInput onChange={(value) => setInputs({ ...inputs, dybde: value })} />

            <div className="row stretch-width">
                <div className="column stretch-width">
                    <h3>Resultat</h3>
                    <div className="result flexApart">
                        <h2>=</h2>
                        <h2>{result} m3</h2>
                    </div>
                </div>

                <div className="column stretch-width">
                    <h3>40% Ekstra</h3>
                    <div className="result flexApart">
                        <h2>=</h2>
                        <h2>{result * 1.40} m3</h2>
                    </div>
                </div>
            </div>

        </div >
    );
}