import { useEffect } from "react";
import PdfPreview from "../../../components/exportToPdf";
import { useState } from "react";

import TemplatePreview from "./malPreview";


const createId = () => {
    return Math.random().toString(36).substr(2, 9);
}


export default function CreateTestMal({ mal }) {
    const defaultReport = {
        firma: {
            navn: "Test",
            arbeidsplass: " Test",
            by: "Test",
            postnummer: "Test",
            kontaktpersoner: [],
            kontaktperson: "Test",
            projekt: {
                navn: "",
                nummer: Math.floor(Math.random() * 1000),
                id: "",
            },
            projekter: [],
        },
        id: "",
        status: {
            navn: "Ikke sendt",
            farge: "red",
        },
        oppgaver: [],
        annet: "",
        utstyr: [],
        mal: mal,
        filer: [],
        projekt: {
            navn: "",
            id: "",
        },
    }
    const [report, setReport] = useState(defaultReport);
    


    function newOppgave() {


        setReport((old) => {
            return {
                ...defaultReport,
                oppgaver: [
                    {
                        title: `Oppgave ${old.oppgaver.length + 1}`,//oppgave.title,
                        id: createId(),
                        type: createId(),
                        icon: "fas fa-file-alt",
                        //TODO: FELLES NEEDS TO CHANGE FOR FELLES ID

                        oppgaveEmner: mal.oppgaver.map(opg => {
                            return {
                                ...opg,
                                id: createId(),
                                type: opg.id,
                                dato: new Date,
                                underAlignments: [
                                    ...Array(3).fill(0).map((_, i) => {
                                        return {
                                            id: createId(),
                                            tasks: [...opg.underAlignments.map(under => {
                                                return {
                                                    ...under,
                                                    //id: createId(),
                                                    value: createValue(under.type.value),
                                                }
                                            })]
                                        }
                                    })
                                ]
                            }
                        })
                    }
                ]
            }
        })
    }

    useEffect(() => {
        newOppgave();
    }, [mal])

    useEffect(() => {
        setReport((old) => {
            return {
                ...old,
                mal: mal,
            }
        }
        )
    }, [mal])

    return (
        <TemplatePreview report={report} />
    )
}

function createValue(type) {
    const number = Math.random()
    const isJustZero = false //number < 0.5;
    console.log(number, isJustZero, type);
    switch (type) {
        case "number":
            // Number 0-20, with likelyhood of 0

            if (isJustZero) {
                return "";
            }
            return Math.floor(Math.random() * 20);
        case "text":
            if (isJustZero) {
                return "";
            }
            return "Test";
        case "date":
            // Random date last 4 years
            let date = new Date(+new Date() - Math.floor(Math.random() * 10000000000)).toLocaleDateString();
            const splitAtDot = date.split(".");
            const replacedDotWithSlash = splitAtDot.join("/");

            return replacedDotWithSlash;
        default:
            return "";
    }
}