import { Calculate, Work } from "@mui/icons-material";
import { makeDateReadable, getDayName } from "../../pages/timeføring/timeføring";
import CircleIcon from "../miniComponents/circleIcon";
import { useHistory, useLocation } from "react-router";
import LoadingWhileEmpty from "../miniComponents/loadingWhileEmpty";
import { useEffect } from "react";
import { IonLoading, IonSpinner } from "@ionic/react";
import { useTranslation } from "react-i18next";




export default function TimelisteCard({ time, date, timeStart, timeEnd, addresse, kommentar, prosjekt, onClick }) {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    return (
        <button className="timeliste-card column stretch-width padding" onClick={onClick}>
            {/*<div className="row flexApart stretch-width wrap">
                <h2>{prosjekt?.navn}</h2>
                <h2>{addresse}</h2>
    </div>*/}

            <div className="row stretch-width center">
                <CircleIcon icon={Work} />
                <div className="column flexApart stretch-width wrap">
                    <h2>{
                        // If date is within the next 7 days, makeDateReadable 
                        makeDateReadable(date, t)
                        /*date.getTime() - new Date().getTime() < 6 * 24 * 60 * 60 * 1000 ?
                            makeDateReadable(date, t) :
                            formatedDate(date)*/
                    }</h2>
                    <p>{timeStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {timeEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                {location.state?.id === time?.id &&
                    <IonSpinner style={{ width: 100, height: 100 }} name="crescent"></IonSpinner>
                }
            </div>
            {!!kommentar && <div className="row flexApart stretch-width">
                <p>{kommentar}</p>
            </div>
            }
            {!!time.utstyr && <div className="row small-gap stretch-width">
                {time.utstyr.map((utstyr) => (
                    <img src={utstyr?.bilde} alt={utstyr?.navn} key={utstyr?.navn} style={{ width: 32, height: 32, borderRadius: 50 }}></img>
                ))}
            </div>

            }
        </button>
    )
}

export function formatedDate(date) {
    const current = date;
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('no-NO', options).format(current);
    //console.log(formattedDate);
    return formattedDate;
}