import { AlarmAdd, AlarmAddRounded, AlarmRounded, ApartmentRounded, ArrowForwardIos, ContactPageRounded, Pages, People, Report } from "@mui/icons-material";
import DefaultWrapper from "../../components/defaultWrapper";
import ButtonListCheckbox from "../../components/miniComponents/buttonListCheckbox";
import CircleIcon from "../../components/miniComponents/circleIcon";
import CustomInput from "../../components/miniComponents/customInput";
import { useEffect, useState } from "react";
import { SaveFirmSettings, getFirmSettings } from "../../firebase";
import { useTranslation } from "react-i18next";




export default function Moduler() {
    const { t } = useTranslation();
    const [moduleSettings, setModuleSettings] = useState({
        rapportModul: false,
        timeføringModul: false,
        utstyrModul: false,
        kundeModul: false,
        prosjektModul: false,
        hmsModul: false
    })
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) return;
        SaveFirmSettings(moduleSettings, "moduleSettings");

    }, [moduleSettings]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await getFirmSettings("moduleSettings");
            setModuleSettings(old => {
                return {
                    ...old,
                    ...settings
                }
            });
            
            setLoading(false);
        }
        getSettings();
    }, []);


    return (
        <DefaultWrapper>
            <div className="content-ny">
                <h3>{t("Included in customer package", "Inkludert I Kundepakke")}</h3>
                <div className="flexApart wrap row">
                    <ButtonListWrappingButton title={t("Report Module", "Rapportmodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, rapportModul: !moduleSettings.rapportModul }) }} active={moduleSettings.rapportModul} icon={ContactPageRounded} />
                    <ButtonListWrappingButton title={t("Time Management Module", "Timeføringsmodul")} description={t("", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, timeføringModul: !moduleSettings.timeføringModul }) }} active={moduleSettings.timeføringModul} icon={AlarmRounded} />
                    <ButtonListWrappingButton title={t("Equipment Module", "Utstyrsmodul")} description={t("", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, utstyrModul: !moduleSettings.utstyrModul }) }} active={moduleSettings.utstyrModul} icon={Pages} />
                    <ButtonListWrappingButton title={t("Customer Module", "Kundemodul")} description={t("", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, kundeModul: !moduleSettings.kundeModul }) }} active={moduleSettings.kundeModul} icon={People} />
                    <ButtonListWrappingButton title={t("Project Module", "Prosjektmodul")} description={t("", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, prosjektModul: !moduleSettings.prosjektModul }) }} active={moduleSettings.prosjektModul} icon={ApartmentRounded} />
                    <ButtonListWrappingButton disabled={true} title={t("Health And Safety", "HMS")} description={t("Coming Soon", "Kommer Snart")} onClick={() => { setModuleSettings({ ...moduleSettings, hmsModul: !moduleSettings.hmsModul }) }} active={moduleSettings.hmsModul} icon={Report} />
                </div>
                <h3>{t("Extra Modules", "Ekstra Moduler")}</h3>
                <CustomInput label={t("Search", "Søk")} />
                <ButtonListWrappingButton title={t("Tripletex integration", "tripletex integrasjon")} description={t("Planned", "Planlagt")} />
            </div>

        </DefaultWrapper>
    )
}

export const ButtonListWrappingButton = ({ title, description, icon, image, onClick, disabled, className, style, active }) => {
    return (
        <button
            className={"card row small-gap" + className}
            onClick={onClick}
            disabled={disabled}
            style={{
                // 2 per row
                flex: `0 0 calc(50% - 10px)`,
                alignItems: "flex-start",
                outline: active ? "1px solid var(--contrastColor)" : "none",
                ...style
            }}>
            <div className="column">
                <div className="row column-on-phone">
                    {icon && <CircleIcon icon={icon} />}
                    {image && <div className="image"><img src={image} alt="" /></div>}
                    <div className="text column small-gap">
                        <h3 className="orange">{title}</h3>
                        <p>{description}</p>
                    </div>
                </div>
                
            </div>
        </button>
    )
}