import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CircleIcon from './circleIcon';

import { ReactComponent as CheckIcon } from '../../assets/icons/checkmark.svg';

export default function ButtonListCheckbox(props) {
    const history = useHistory();
    const [checked, setChecked] = useState(props.checked || false);

    function navigateToNewPage(path) {
        history.push(path);
    }

    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                if (props?.onPress) props?.onPress(e);
                if (props?.onClick) props?.onClick(e);
                if (props?.navigateTo) navigateToNewPage(props.navigateTo);
                if (props?.onCheckboxChange) props.onCheckboxChange(!props.checked);
            }}
            className='button-list-button'
        >
            {props?.icon && <CircleIcon icon={props.icon}></CircleIcon>}
            {props?.image && <img src={props.image} alt=''></img>}

            <div className='row small-gap stretch-width flexApart'>
                <div className='column stretch-width small-gap'>
                    {props?.title && <h3 className='orange' style={{ textAlign: "start" }}>{props.title}</h3>}
                    {props?.description && <p>{props.description}</p>}
                    {props?.children && props.children}
                </div>
            </div>
            <div className={'checkbox ' + (props?.checked ? 'checked' : '')}>
                <CheckIcon />
            </div>

        </button >
    );
}
