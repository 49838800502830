import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";
import CustomInput from "../../../components/miniComponents/customInput";
import { createId, getClients, getCompanyUsers, getProjects, getProjectsByCompanyId, setTimeListForUser } from "../../../firebase";
import { SearchWithPopup } from "../../../components/oppgaver/searchWithPopup";


//material-symbols_compare-arrows-rounded
import { ReactComponent as SwitchIcon } from '../../../assets/icons/material-symbols_compare-arrows-rounded.svg';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';
import CustomDropdown from "../../../components/miniComponents/customDropdown";
import { useHistory } from "react-router";
import { UserContext } from "../../../App";


import * as React from 'react';
import TextField from '@mui/material/TextField';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import UtstyrSearchWithPopup from "../../../components/searchWithPopups/utstyrSearch";
import { Autocomplete } from "@mui/material";
import { CustomPaper, customSxStyles } from "../timeføring";
import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { toDateString } from "./editTimeTimeplan";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

dayjs.extend(weekday);
dayjs.locale({ ...dayjs.Ls.en, weekStart: 1 });



//AdapterDayjs.extendLocalizedFormat(dayjs);


const StyledTextField = styled(TextField)({
    // Add your custom styles here
    width: '100%',
    marginBottom: '20px',
    // Example: Change the text field border color
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'blue',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'purple',
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',  // This sets the theme mode to dark
    },
});




export default function NyTid() {
    const { t } = useTranslation();
    const { userContext } = useContext(UserContext);
    const history = useHistory();

    const timesToAdd = history.location.state?.time?.dato ?
        {
            dato: toDateString(createDateOutOfFirebaseTimestamp(history.location.state?.time?.dato)),
        } : {}

    const [time, setTime] = useState({
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        place: {
            by: "By",
            addresse: "Adresse",
            postnummer: "Postnummer"
        },
        klient: null,
        people: [],
        multipleDays: false,
        dato: "",
        secondDate: "",
        notificationSent: false,
        prosjekt: null,
        bruker: userContext.companyUser,
        utstyr: [],
        ...history.location.state?.time,
        ...timesToAdd
    });

    const [people, setPeople] = useState([]);
    const [klienter, setKlienter] = useState([]); // [ {id: 1, name: "Klient 1"}, {id: 2, name: "Klient 2"}
    const [projects, setProjects] = useState([]);


    const [selectedDates, setSelectedDates] = useState([]);

    useEffect(() => {
        setTime({ ...time, days: selectedDates })
    }, [selectedDates])

    const handleDateChange = (date) => {
        const newDate = date.toDate();
        // If it isn't already in the array, add it
        if (!selectedDates.find((selectedDate) => selectedDate?.getTime() === newDate?.getTime())) {
            setSelectedDates([...selectedDates, newDate]);
        }
        // Otherwise, remove it
        else {
            setSelectedDates(selectedDates.filter((selectedDate) => selectedDate?.getTime() !== newDate?.getTime()));
        }
    };

    function ServerDay(props) {
        const { highlightedDays, day, outsideCurrentMonth, ...other } = props;
        const isSelected = selectedDates.some((selectedDate) => selectedDate?.getTime() === day.$d?.getTime());

        day.selected = isSelected;

        return (
            <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={isSelected ? <div className="selected-circle" /> : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }





    useEffect(() => {
        const onUpdate = (data) => {
            setPeople(data)
        }

        async function getCompanypeople(onUpdate) {
            await getCompanyUsers(onUpdate)
        }
        getCompanypeople(onUpdate)
    }, []);

    useEffect(() => {
        const onUpdate = (data) => {
            setKlienter(data)
        }

        async function getCompanyClients(onUpdate) {
            await getClients(onUpdate)
        }
        getCompanyClients(onUpdate)
    }, []);

    useEffect(() => {
        const onUpdate = (data) => {
            setProjects(data)
        }

        async function getCompanyProjects(onUpdate) {
            await getProjects(onUpdate)
        }
        getCompanyProjects(onUpdate)
    }, []);

    useEffect(() => {
        console.log(time.prosjekt)
        if (time?.prosjekt?.klokkeStart) {
            setTime(old => { return { ...old, startDate: time.prosjekt.klokkeStart } })
        }
        if (time?.prosjekt?.klokkeSlutt) {
            setTime(old => { return { ...old, endDate: time.prosjekt.klokkeSlutt } })
        }
    }, [time?.prosjekt])

    const flattenedOptions = projects.map((project) => {
        return {
            ...project,
            clientName: project.navn,
            clientId: project.id
        }
    })


    const onDone = async () => {
        time.people.forEach(async (person) => {
            if (time.multipleDays) {
                const forEachDay = time.days

                forEachDay.forEach(async (date) => {
                    console.log(date)
                    console.log(time.startDate)
                    await setTimeListForUser(time, person, new Date(date));
                })
            } else {
                await setTimeListForUser(time, person, new Date(time.dato));
            }
        })

        history.goBack();
    }

    const extraButton = <button onClick={onDone}>Ferdig <Checkmark /></button>


    return (
        <DefaultWrapper extraButton={extraButton}>

            <div className="content-ny">
                <div className="row">
                    <h2>{t("Add new time", "Legg til ny tid")}</h2>
                </div>

                <div className="column small-gap">
                    <h3>{t("People", "Personer")}</h3>
                    <SearchWithPopup items={people} onChoise={
                        (item) => {
                            if (time.people.find((person) => person.id === item.id)) {
                                return;
                            }

                            //If there is no person with the same id, we do not add it, as we need the user to have an id.
                            if (!item.invId) return; //Only thing a user has that defines them as a user. Should change this later
                            //Should return a toast
                            // TODO add toast
                            setTime({ ...time, people: [...time.people, item] })
                        }
                    } />

                    <div className="row wrap small-gap">
                        {time.people.map((person, index) => {
                            return (
                                <div key={person.id} className="row">
                                    <button onClick={() => {
                                        let newPeople = [...time.people];
                                        newPeople.splice(index, 1);
                                        setTime({ ...time, people: newPeople })
                                    }}>{person.navn}  X</button>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>

                <Autocomplete
                    PaperComponent={CustomPaper}
                    id="Timeføring"
                    value={time?.prosjekt || { navn: "" }}
                    options={flattenedOptions}
                    filterOptions={(options, { inputValue }) => {
                        try {
                            let results = [];
                            let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                            options.forEach(option => {
                                // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                if (option && option?.clientName && option?.clientName.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                                else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                            });

                            return results;
                        } catch (error) {
                            console.error("Error filtering options:", error);
                            return []; // Return an empty array in case of error
                        }
                    }}
                    groupBy={(option) => option?.clientName}
                    getOptionLabel={(option) => option?.nummer ? option?.nummer + " - " + option.navn : option.navn}
                    style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                    sx={{ ...customSxStyles, minWidth: 200, backgroundColor: "var(--input-background)", backgroundClip: "var(--input-background)" }}
                    renderInput={(params) => <TextField {...params} label={t("Project", "Prosjekt")} />}
                    renderGroup={(params) => (
                        <div key={params.key} style={{ background: "var(--input-background)" }}>
                            <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                            <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                {params.children}
                            </p>
                        </div>
                    )}
                    fullWidth={true}
                    onChange={async (e, value) => {
                        if (!value) return setTime((old) => { return { ...old, kunde: null, prosjekt: null } })
                        setTime((old) => { return { ...old, prosjekt: value } })
                    }}
                />
                <div className="column small-gap">
                    <div className="row flexApart bottom-align">
                        <h3>{t("Date", "Dato")}</h3>
                        <button onClick={() => setTime({ ...time, multipleDays: !time.multipleDays })}>{time.multipleDays ? "Flere dager" : "En dag"}
                            <SwitchIcon />
                        </button>
                    </div>
                    {time.multipleDays &&
                        <ThemeProvider theme={darkTheme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    date={null} // Since you are selecting multiple dates, no single date is highlighted
                                    onChange={handleDateChange}
                                    slotProps={{
                                        day: {
                                            highlightedDaysTest: selectedDates,
                                        },
                                    }}
                                    slots={{
                                        day: ServerDay,
                                    }}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    }


                    <div className="column small-gap">
                        {!time.multipleDays &&
                            <CustomInput label={"Fra"} type="date" value={time.dato} noIcon={true} onChange={(e) => setTime({ ...time, dato: e.target.value })} />
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="column small-gap">
                        <h3>{t("Start time", "Start tid")}</h3>
                        <CustomInput type="time" noIcon={true} value={time.startDate} onChange={(e) => setTime({ ...time, startDate: e.target.value })} />
                    </div>
                    <div className="column small-gap">
                        <h3>{t("End time", "Ende tid")}</h3>
                        <CustomInput type="time" noIcon={true} value={time.endDate} onChange={(e) => setTime({ ...time, endDate: e.target.value })} />
                    </div>
                </div>

                <div className="column small-gap">
                    <h3>{t("Comment", "Kommentar")}</h3>
                    <CustomInput type="text" value={time.comment} onChange={(e) => setTime({ ...time, comment: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Equipment", "Utstyr")}</h3>
                    <UtstyrSearchWithPopup onChange={(e) => setTime({ ...time, utstyr: e })} />
                </div>
            </div>
        </DefaultWrapper>
    )
}

const getDaysBetweenDates = (startDate, endDate) => {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
}