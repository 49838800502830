import { useTranslation } from "react-i18next";
import DefaultWrapper from "../../../components/defaultWrapper";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { SaveFirmSettings } from "../../../firebase";




export default function AdminInstillinger() {
    const { t } = useTranslation();
    return (
        <DefaultWrapper>
            <div className="content-ny">
                <h1>{t("Admin settings", "Admininnstillinger")}</h1>
                <div className="button-list">
                    <ButtonListButton title={t("Company", "Firma")} description={t("Change the company settings", "Endre Firma Innstillinger, navn, logo, etc.")} icon={null} navigateTo="/admin/instillinger/firma"></ButtonListButton>
                    <ButtonListButton title={t("Time management settings", "Timeførings Innstillinger")} description={t("Change time management settings", "Endre Timeførings Instillinger")} icon={null} navigateTo="/admin/instillinger/timeforing"></ButtonListButton>
                    {/*
                    <ButtonListButton title={t("Schedule settings", "Arbeidsplan Innstillinger")} description={t("Change schedule settings", "Endre Arbeidsplan Instillinger")} icon={null} navigateTo="/admin/instillinger/arbeidsplan"></ButtonListButton>
                    */}
                    <ButtonListButton title={t("Report settings", "Rapport Innstillinger")} description={t("Change report settings", "Endre Rapport Instillinger")} icon={null} navigateTo="/admin/instillinger/rapporter"></ButtonListButton>
                </div>
            </div>
        </DefaultWrapper>
    )
}

export async function saveSettings(settings, settingsString) {
    await SaveFirmSettings(settings, settingsString);
}