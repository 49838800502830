import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import BetongKalkulator from "../components/betong Kalkulator/Betong";
import KubbikKalkulator from "../components/betong Kalkulator/Kubbik";
import DefaultWrapper from "../components/defaultWrapper";
import { useState } from "react";

export default function Kalkulator() {

    const [segment, setSegment] = useState("Betong");

    return (
        <DefaultWrapper>
            <div className="content-ny column flexApart">
                <IonSegment value={segment} className="segment">
                    <IonSegmentButton value="Betong" onClick={() => setSegment("Betong")}>
                        <IonLabel>Betong Kalkulator</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Kubikk" onClick={() => setSegment("Kubikk")}>
                        <IonLabel>Kubikk Kalkulator</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {segment === "Betong" && <BetongKalkulator />}
                {segment === "Kubikk" && <KubbikKalkulator />}
            </div>
        </DefaultWrapper>
    );
}