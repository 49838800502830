import React, { useState, useEffect } from 'react';
import DraggableList from 'react-draggable-list';
import ButtonListButton from './buttonListButton';
import CircleIcon from './circleIcon';

const ITEM_HEIGHT = 72; // Static height
const ITEM_GAP = 10;    // Gap between items

const ListItem = ({ item, commonProps, dragHandleProps, itemSelected }) => {
    // Dynamic style for the dragged item
    const draggedItemStyle = {
        zIndex: itemSelected ? 1000 : 'auto',
        boxShadow: itemSelected ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : 'none',
        // Add more styles as needed
        padding: "0px 20px"
    };

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    return (
        <div className='button-list-button' onClick={(e) => {
            console.log("CLICKED THIS BUTTON")
            if (e.target.className.includes('drag-handle')) {
                return;
            }
            commonProps.itemSelected(item);
        }} style={itemSelected ? draggedItemStyle : { padding: "0px 20px" }}>
            {item?.icon && <CircleIcon icon={item?.icon} />}
            <div className={'column small-gap stretch-width'}>
                <h2 className={'orange '}>{item?.title}</h2>
                <p>{item?.description}</p>
            </div>
            <div className={'column small-gap'}>
                <div {...dragHandleProps} onDragStart={handleDragStart}

                    className="drag-handle" style={{ cursor: 'grab', padding: 20 }}>
                    ☰
                </div>
            </div>
        </div>
    );
};

ListItem.getDragHeight = function () {
    return 72; // Adjust this value based on your item's height while dragging
};




const MyDraggableList = ({ items: initialItems, onOrderChange, onClickItem }) => {
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    const handleOrderChange = (newList) => {
        console.log(newList);
        setItems(newList);
        onOrderChange(newList);
    };

    const itemSelected = (item) => {
        onClickItem(item);
    };
    console.log(initialItems);
    console.log(items);
    if (!items) {
        return null;
    }

    return (
        <DraggableList
            itemKey="id"
            list={items}
            template={ListItem}
            onMoveEnd={onOrderChange}
            commonProps={{ itemSelected, onClickItem }}
        />

    );

    return (
        <DraggableList
            itemHeight={ITEM_HEIGHT}
            itemSpacing={ITEM_GAP}
            list={items}
            template={ListItem}
            onMoveEnd={handleOrderChange}
            commonProps={{ itemSelected, onClickItem }}
        />
    );
};

export default MyDraggableList;
