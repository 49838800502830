import { ImageNotSupportedRounded } from "@mui/icons-material"
import { useEffect, useState } from "react"




export default function LoadingImage(props) {
    const { item } = props
    const [loadedImage, setLoadedImage] = useState(false)
    const [image, setImage] = useState(item?.preloadBilde || item?.bilde || item?.img || item?.image)

    console.log(item?.preloadBilde || item?.bilde || item?.img || item?.image)

    useEffect(() => {
        const image = new Image()
        if (props.medium === true) {
            image.src = item?.mediumBilde || item?.bilde || item?.img || item?.image
        } else {
            image.src = item?.bilde || item?.img || item?.image
        }
        image.onload = () => {
            setLoadedImage(image)
        }
    }, [])

    if (loadedImage) return (
        <img className={props?.className} style={props?.style} src={loadedImage.src} alt='Bilde' /> || <ImageNotSupportedRounded className={props?.className} />
    )

    return (
        <img className={props?.className} style={props?.style} src={image} alt='Bilde' /> || <ImageNotSupportedRounded className={props?.className} />
    )
}