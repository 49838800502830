import { useEffect, useState } from "react"




export default function TopBarChoosePage(props) {
    const [state, setState] = useState(props.options[0]);

    useEffect(() => {
        // Find the option in the new props that matches the current state's name
        const matchingOption = props.options.find(option => option.name === state.name);
        
        // If a matching option is found, update the state with it
        if (matchingOption) {
            setState(matchingOption);
        }
    }, [props.options, state.name]);

    return (
        <div className="column">
            <div className="row top-bar-holder stretch-width center">
                {props?.options?.map((option, index) => (
                    <button key={index} className={"top-bar-button " + (state.name === option.name ? "active" : "")} onClick={() => setState(option)}>
                        {option.name}
                    </button>
                ))}

            </div>
            {state?.component && state.component}
        </div>
    )
}