import { useEffect, useState } from "react";
import { getAuthUser, getChatMessages, getChats, getCompanyUser, getNotes } from "../firebase";
import ButtonListButton from "../components/miniComponents/buttonListButton";
import DefaultWrapper from "../components/defaultWrapper";
import { useHistory } from "react-router";


//import { ReactComponent as ChatIcon } from '../assets/icons/mdi_chat.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/mdi_account-group.svg';
import { ReactComponent as PersonIcon } from '../assets/icons/material-symbols_person.svg';
import { ReactComponent as NotaterIcon } from '../assets/icons/mdi_notebook.svg';
import LoadingWhileEmpty from "../components/miniComponents/loadingWhileEmpty";
import { CheckIfOnPC } from "../App";
import { useTranslation } from "react-i18next";


export default function NotatLobby(props) {
    const { t } = useTranslation();
    const [notater, setNotater] = useState([]);
    const history = useHistory();

    useEffect(() => {
        async function getNotater() {
            const onUpdate = (notater) => {
                setNotater(notater);
            }

            const user = await getAuthUser();
            if (!user) return;

            const companyUser = await getCompanyUser(user);
            if (!companyUser) return;

            getNotes(onUpdate, companyUser);
        }
        getNotater();
    }, []);




    return (
        <DefaultWrapper noBackButton={CheckIfOnPC()}>
            <div className="content-ny column">
                <h1>{t("Notes", "Notater")}</h1>
                <div className="button-list">
                    <LoadingWhileEmpty buttonList={true}>
                        {notater.map((notat) => {
                            //console.log(notat.brukere)
                            return (
                                <ButtonListButton
                                    icon={NotaterIcon}
                                    key={notat.id} onClick={() => {
                                        history.push("/notater/" + notat.id, { notat: notat })
                                    }} title={notat.navn} description={notat.beskrivelse} />
                            )
                        }
                        )}
                    </LoadingWhileEmpty>
                </div>
                <button className="bottom-right-button" onClick={() => {
                    history.push("/notater/ny", { notater: null })
                }
                }>{t("New Note", "Nytt Notat")} +</button>
            </div>
        </DefaultWrapper>
    )
}