
import { IonButton, IonContent, IonDatetime, IonHeader, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { createGesture } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from "react";
import TilbakeKnapp from "../components/miniComponents/tilbakeKnapp";

import { ReactComponent as SearchIcon } from '../assets/icons/material-symbols_search.svg';
import { searchObjects } from "./search";
import Background from "./miniComponents/background";
import { HeaderContext } from "../App";
import Header from "./header";
import { Capacitor } from "@capacitor/core";


export default function WrapperForTyping(props) {

    const history = useHistory();
    const pageElRef = useRef();

    return (
        <IonPage>
            <IonContent fullscreen ref={pageElRef} className={(props?.header ? "wrapperHeaderContent" : "") + " wrapper" }>
                <Background />
                <div className={"row flexApart padding wrapper wrapper-for-typing-top " + (Capacitor.getPlatform() === "ios" ? "padding ios-padding-top" : "padding")}>
                    {!props?.noBackButton && <TilbakeKnapp lagring={props?.lagring} onClick={props.onBack} skipNormalBehaviour={props.skipBack} />}
                    {props?.søkefelt &&
                        <Søkefelt updateFilteredItems={props.updateFilteredItems} itemsToFilter={props.itemsToFilter} />
                    }
                    {props?.extraTitle && props.extraTitle}
                    {props?.extraButton && props.extraButton}
                </div>
                <div className="wrapper-padding"/>
                {props.children}
                {//props?.header && <Header />
                }
            </IonContent>
        </IonPage>
    );
}


export function Søkefelt(props) {
    const inputRef = useRef(null);
    const [searchString, setSearchString] = useState('');


    useEffect(() => {
        filterBrukere();
        console.log(props.itemsToFilter);
        console.log(searchString);
    }, [searchString, props.itemsToFilter]);

    function filterBrukere() {
        const itemsToFilter = props.itemsToFilter;
        console.log(props?.doNotShowScoreUnderZero);
        let filtered = props?.doNotShowScoreUnderZero ? searchObjects(itemsToFilter, searchString).filter((item) => item.score > 0) : searchObjects(itemsToFilter, searchString);
        props.updateFilteredItems(filtered);
    }

    return (
        <button className="søkefelt" onClick={() => inputRef.current.focus()}>
            <input className="søkefelt-input" onChange={(e) => setSearchString(e.target.value)} ref={inputRef} type="text" placeholder="Søk" />
            <SearchIcon />
        </button>
    );
}