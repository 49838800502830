import React from 'react';
import { createRoot } from 'react-dom/client';
import App, { UserContextProvider } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import { AuthProvider } from "./auth";
import { render } from "react-dom";                 // add this
import { setupIonicReact } from '@ionic/react';
import './i18next.js'
import { CircularProgress, Skeleton } from '@mui/material';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const container = document.getElementById('root');
const root = createRoot(container);
document.addEventListener = function () {
  // Duplicating functionality from Capacitor.js native-bridge.ts, otherwise when cordova.js calls addEventListener for 'deviceready' will not immediately fire if 'deviceready' has already emitted.
  // Need to duplicate capacitor logic because capacitor injects native-bridge.ts code at the top of the head, so this function overwrites it.
  // @see https://github.com/ionic-team/capacitor/blob/89cddcd6497034146e0938ce8c264e22e7baba52/core/native-bridge.ts#L152
  const [eventName, handler] = arguments;
  if (eventName === 'deviceready' && handler) {
    Promise.resolve().then(handler);
  }
  // We don't care about back button error checks or default 'backbutton' action as we have @capacitor/app plugin installed, and set a listener within app.component.ts
  (window.EventTarget || Document).prototype.addEventListener.apply(this, arguments);
};

setupIonicReact();

root.render(
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.9/css/all.css" integrity="sha384-5SOiIsAziJl6AWe0HWRKTXlfcSHKmYV4RBF18PPJ173Kzn7jzMyFuTtk8JA7QQG1" crossOrigin="anonymous"></link>
    <script src="https://www.google.com/recaptcha/enterprise.js" async defer></script>
    <React.StrictMode>
      <React.Suspense fallback={
        <div className='column padding center'>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="circular" width={80} height={80} />
          <Skeleton variant="rectangular" width={"100%"} height={120} />
          <Skeleton variant="rounded" width={"100%"} height={160} />
        </div>
      }>
        <App />
      </React.Suspense>
    </React.StrictMode>

  </div>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
