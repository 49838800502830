
import { acceptInvitation, checkIfUserIsAdmin, getAuthUser, getAuthUserId, getCompaniesForUser, getCompanyUser, getInvitations, logout } from '../../firebase';
import DefaultWrapper from '../../components/defaultWrapper';
import { useContext, useEffect, useState } from 'react';
import { useHistory, browserHistory } from 'react-router';
import ButtonListButton from '../../components/miniComponents/buttonListButton';

import { ReactComponent as MarkEmailIcon } from '../../assets/icons/material-symbols_mark-email-unread-outline-rounded.svg';
import { ReactComponent as WorkIcon } from '../../assets/icons/material-symbols_work.svg';
import { ResetContext, UserContext, getUserContext } from '../../App';
import { useTranslation } from 'react-i18next';
import ButtonOnlyHitOnce from '../../components/miniComponents/buttonOnlyHitOnce';
import { setCompanyId } from '../../contexts/companyContext';



export default function Tab3() {
  const { t } = useTranslation();
  const [bedrifter, setBedrifter] = useState([]);
  const [invitasjoner, setInvitasjoner] = useState([]);
  const history = useHistory();
  const { reset, setReset } = useContext(ResetContext);
  const [isAdmin, setIsAdmin] = useState(false);

  const { userContext, setUserContext } = useContext(UserContext);


  useEffect(() => {
    async function checkAdmin() {
      const result = await checkIfUserIsAdmin(userContext.user)
      console.log(result)
      setIsAdmin(result)
    }
    checkAdmin()
  }, [userContext])


  function navigateToNewPage(path, information) {
    console.log(information)
    getUserContext()
    // De-render all components
    history.push({
      pathname: path,
      state: information
    });

    // Replace the current history entry with the new page
    // This clears previous history entries
    setReset(true);
  }

  

  async function getInvitasjoner() {
    const onUpdate = (invitasjoner) => {
      const filteredInvitasjoner = invitasjoner.filter((invitasjon) => {
        if (!invitasjon.accepted) return invitasjon;
      })
      setInvitasjoner(filteredInvitasjoner);
    }

    const userId = userContext.user.uid;
    console.log(userId);

    getInvitations(userContext.user, onUpdate)
    /*.then((invitasjoner) => {
        console.log(invitasjoner);
        const filteredInvitasjoner = invitasjoner.filter((invitasjon) => {
            if (!invitasjon.accepted) return invitasjon;
        })
        setInvitasjoner(filteredInvitasjoner);
    })
    .catch((error) => {
        console.log(error);
    });*/
  }

  async function getBedrifter() {
    //firebase get bedrifter
    const onUpdate = async (bedrifter) => {
      console.log(bedrifter);
      setBedrifter(bedrifter);
      localStorage.setItem('bedrifter', JSON.stringify(bedrifter));
    }
    const userId = userContext.user.uid;
    console.log(userId);
    getCompaniesForUser(userId, onUpdate)
  }

  const logOutEndFunction = () => {
    navigateToNewPage("/login");
  }



  useEffect(() => {
    async function getFromDatabase() {
      console.log("Getting from database");
      getBedrifter();
      getInvitasjoner();
    } 

    getFromDatabase();
  }, []);

  return (
    <DefaultWrapper>
      <div className="content-ny">
        {invitasjoner.length > 0 &&
          <div className="column">
            <h1>{t("Invitation", "Invitasjoner")}</h1>
            <div className="button-list">
              {invitasjoner.map((invitasjon, index) => {
                console.log(invitasjon)
                if (!invitasjon.accepted) return (
                  // Needs an accept and decline button
                  <ButtonListButton key={invitasjon.id || index} title={invitasjon.companyName} description={t("Invitation", "Invitert")} icon={MarkEmailIcon} onClick={async () => {
                    acceptInvitation(await getAuthUserId(), invitasjon)
                  }} />
                )
              })}
            </div>
          </div>
        }

        {bedrifter.length > 0 &&
          <div className="column">
            <h1>{t("Companies", "Bedrifter")}</h1>
            <div className="button-list">
              {bedrifter.map((bedrift, index) => {
                console.log(bedrift)
                return (
                  <ButtonListButton key={bedrift?.id || index} title={bedrift?.navn} description={bedrift?.adresse} icon={WorkIcon} onClick={async () => {
                    //Should take you to a new page to accept the invitation
                    console.log(bedrift.id)
                    setCompanyId(bedrift.id)

                    const userContextToUpdate = await getUserContext()
                    setUserContext(old => {
                      return { user: old.user, companyUser: userContextToUpdate.companyUser }
                    });
                    navigateToNewPage('/hjem')
                    //navigateToNewPage('/hjem')
                  }} />
                );
              })}

            </div>
          </div>
        }

        {isAdmin &&
          <div className="column">
            <ButtonOnlyHitOnce className="button" onClick={() => {
              // navigate to create company page
              navigateToNewPage('/createCompany')
            }}>{t("Create new company", "Opprett ny bedrift")}</ButtonOnlyHitOnce>
          </div>
        }

        <div className="center-row padding">
          <button onClick={() => logout(logOutEndFunction)
          }>{t("Log out", "Logg ut")}</button>
        </div>
      </div>
    </DefaultWrapper>
  );
};


