import { useHistory } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import { useEffect, useState } from "react";
import { createDateOutOfFirebaseTimestamp, getMonth } from "../timeføring";
import ItemDropdownButtons from "../../../components/miniComponents/ItemDropdownButtons";
import TopBarChoosePage from "../../../components/miniComponents/choosePageTopBar";
import { DataGrid } from '@mui/x-data-grid';
import ArbeidsplanKalender from "./ArbeidsplanMedFolk";
import { useTranslation } from "react-i18next";


export default function TimeAdminPage() {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <DefaultWrapper>
            <div className="content-ny">
                <div className="row flexApart">
                    <h2>{t("Time Admin", "Time Admin")}</h2>
                    <button onClick={() => {
                        console.log("clicked");
                        //Go to
                        history.push({
                            pathname: "/timeAdmin/nytid",
                            state: { time: "new" }
                        });

                    }}>{t("New Time", "Ny tid")} +</button>
                </div>
                <ArbeidsplanKalender />
                {/*}
                <TopBarChoosePage options={[
                    /*{ name: "Dags-version", component: <NormalSchedules schedules={combinedSchedulesBasedOnDate} /> },*/
                    /*{ name: "Liste-version", component: <WorkerScheduleTable schedules={schedules} /> },*/
                    //{name: "Kalender", component: <ArbeidsplanKalender schedules={schedules} />},
                    //{ name: "Oversikt", component: <AdminTimeOverview></AdminTimeOverview> }]} />
                }
            </div>
        </DefaultWrapper>
    )
}

export const filterSchedulesBasedOnDate = (schedules, t) => {
    
    const groupedByDay = schedules.reduce((acc, current) => {
        if (!current.endDate) return acc;
        if (current.startDate) {
            const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

            if (!isNaN(date)) {
                const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                if (!acc.has(day)) {
                    acc.set(day, []);
                }

                acc.get(day).push(current);
            }
        }

        return acc;
    }, new Map());

    for (let [_, recordings] of groupedByDay) {
        recordings.sort((a, b) => {
            if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
                return b.endDate.toDate() - a.endDate.toDate();
            } else {
                return 0;
            }
        });
    }

    const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse().map(([date, recordings]) => {
        const [year, month, day] = date.split('-');
        const title = `${day}. ${getMonth(parseInt(month) - 1, t)} ${year}`; // change the title to the desired format

        return {
            title,
            dates: recordings,
        };
    });

    return arrayOfPreviousRecordings;
}