import DefaultWrapper from "../../components/defaultWrapper";
import ButtonListButton from "../../components/miniComponents/buttonListButton";

import { ReactComponent as ChatIcon } from '../../assets/icons/material-symbols_chat.svg';
import { ReactComponent as RapportIcon } from '../../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as Warning } from '../../assets/icons/material-symbols_warning-rounded.svg';
import { ReactComponent as UtstyrIcon } from '../../assets/icons/mdi_wrench.svg';
import { ReactComponent as KalkulatorIcon } from '../../assets/icons/mdi_calculator.svg';
import { ReactComponent as NotaterIcon } from '../../assets/icons/mdi_notebook.svg';
import { ReactComponent as AdminIcon } from '../../assets/icons/eos-icons_admin.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/material-symbols_person.svg';
import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import { ReactComponent as WorkIcon } from '../../assets/icons/material-symbols_work.svg';
import { useHistory } from "react-router";

export default function HMSMeny() {
    const history = useHistory();


    return (
        <DefaultWrapper>
            <div className="content-ny column">
                <h1>HMS</h1>
                <div className="row button-list">
                    <ButtonListButton
                        title="RUH Skjema"
                        description="Risiko og ulykkes hendelse"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/ruh")
                        }}
                    />
                    <ButtonListButton
                        title="Avvik"
                        description="Avviksrapport"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/avvik")
                        }}
                    />
                    <ButtonListButton
                        title="SJA Skjema"
                        description="Sikker jobb analyse"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/sja")
                        }
                        }
                    />
                    <ButtonListButton
                        title="Vernerunde"
                        description="Vernerunde"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/vernerunde")
                        }}
                    />
                    <ButtonListButton
                        title="Stoffkartotek"
                        description="Stoffkartotek"
                        icon={RapportIcon}
                        onClick={() => {
                            //history.push("/hms/stoffkartotek")
                        }}
                    />
                </div>
            </div>
        </DefaultWrapper>
    )
}