import { IonInput } from "@ionic/react";
import MathInput from "./mathInput";
import { useEffect, useState } from "react";
import DefaultWrapper from "../defaultWrapper";


export default function BetongKalkulator() {
    const [inputs, setInputs] = useState({
        bredde: {},
        lengde: {},
        dybde: {},
    });

    const [result, setResult] = useState(0);

    const calculateAmountInCm = (item) => {
        if (!Number(item.value)) return 0;

        if (item.unit === "dm") return Number(item.value) * 10;

        if (item.unit === "m") return Number(item.value) * 100;

        return Number(item.value);
    }


    const calculate = () => {
        let bredde = calculateAmountInCm(inputs.bredde)
        let lengde = calculateAmountInCm(inputs.lengde)
        let dybde = calculateAmountInCm(inputs.dybde)
        console.log(bredde, lengde, dybde)

        let result = ((bredde * lengde * dybde) / 1000) * 2.5;
        console.log(result)
        return result;
    }

    useEffect(() => {
        setResult(calculate())
    }, [inputs])


    return (
            <div className="column">
                <h1>Betongkalkulator</h1>
                <h3>Bredde</h3>
                <MathInput onChange={(value) => setInputs({ ...inputs, bredde: value })} />
                <h3>Lengde</h3>
                <MathInput onChange={(value) => setInputs({ ...inputs, lengde: value })} />
                <h3>Dybde</h3>
                <MathInput onChange={(value) => setInputs({ ...inputs, dybde: value })} />

                <IonInput label="Disabled input" value="/1000" disabled={true}></IonInput>
                <IonInput label="Disabled input" value="X 2.5" disabled={true}></IonInput>
                <h3>Resultat</h3>
                <div className="result flexApart">
                    <h2>=</h2>
                    <h2>{result} kg</h2>
                </div>

            </div >
    );
}