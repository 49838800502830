import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from 'react-spring';
import { Timestamp } from "firebase/firestore";
import { throttle } from "lodash";
import { createDateOutOfFirebaseTimestamp } from "../../pages/timeføring/timeføring";
import { ArrowLeft, ArrowLeftRounded, ArrowRight, ArrowRightRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";



function getValidDate(date) {
    if (date instanceof Timestamp) {
        return createDateOutOfFirebaseTimestamp(date);
    }
    return new Date(date);
}

const TimePickerCarousel = React.memo(({ initialDate, onChange }) => {
    const { t } = useTranslation();
    const validInitialDate = initialDate ? getValidDate(initialDate) : new Date();
    const [selectedDate, setSelectedDate] = useState(validInitialDate);
    const [isAnimating, setIsAnimating] = useState(false);
    const scrollRef = useRef(null);
    const scollParentRef = useRef(null);
    const centerRef = useRef(null);

    const days = [t("Sun","Søn"), t("Mon","Man"), t("Tue","Tir"), t("Wed", "Ons"), t("Thu", "Tor"), t("Fri", "Fre"), t("Sat","Lør")];
    const months = [t("January","Januar"), t("February", "Februar"), t("March", "Mars"), t("April", "April"), t("May","Mai"), t("June", "Juni"), t("July","Juli"), t("August","August"), t("September", "September"), t("October","Oktober"), t("November","November"), t("December","Desember")];


    const handleDateChange = (day) => {
        const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
        setSelectedDate(newDate);
        onChange(newDate);
    };

    const handleMonthChange = (increment) => {
        const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + increment, 1);
        setSelectedDate(newDate);
        onChange(newDate);
    };

    useEffect(() => {
        if (scrollRef.current) {
            const selectedDayIndex = selectedDate.getDate() - 1;
            const selectedDayButton = scrollRef.current.children[selectedDayIndex];
            if (selectedDayButton) {
                scrollRef.current.scrollLeft = selectedDayButton.offsetLeft - scrollRef.current.offsetLeft - (scrollRef.current.clientWidth / 2 - selectedDayButton.clientWidth / 2);
            }
        }
    }, [selectedDate]);

    const animProps = useSpring({
        opacity: isAnimating ? 0.5 : 1,
        config: { tension: 280, friction: 60 }
    });

    const selectCenterDate = () => {
        // ... logic to select the center date ...
    };

    const throttledHandleScroll = throttle(selectCenterDate, 200);

    useEffect(() => {
        const scrollElement = scrollRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', throttledHandleScroll);
            return () => scrollElement.removeEventListener('scroll', throttledHandleScroll);
        }
    }, [throttledHandleScroll]);

    const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
    const datesArray = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    const debouncedSelectCenterDate = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsAnimating(false);
        }, 200);
        selectCenterDate();
    };


    // JSX for the component
    return (
        <animated.div className='column noGap center-column maskImage' ref={scollParentRef} style={{
            ...animProps,
            position: 'relative', overflow: 'hidden', maxWidth: "100%",
        }}>
            <div style={{
                display: 'flex', justifyContent: 'space-between', width: 300,
            }} className={'row center'}>
                <button className="center row" style={{ width: 80 }} onClick={() => handleMonthChange(-1)}>{<ArrowLeftRounded />}</button>
                <span>{months[selectedDate.getMonth()]}</span>
                <button className="center row" style={{ width: 80 }} onClick={() => handleMonthChange(1)}>{<ArrowRightRounded />}</button>
            </div>

            <div className='row small-gap ' style={{
                overflow: "scroll",
                width: 'calc(100% + 40px)', // Make it wider
                position: 'relative', // Move it to the left
                scrollSnapType: 'x mandatory',
                scrollSnapAlign: 'center',
                scrollBehavior: 'smooth',
                paddingInline: "50%",
                gap: 10,
                paddingBlock: 20,
                // Applying the mask
                maskImage: 'linear-gradient(to right, transparent, black 20%, black 80%, transparent)',
            }}
                ref={scrollRef}
                onScroll={debouncedSelectCenterDate}
            >
                {
                    datesArray.map((day, index) => (
                        <button className='column small-gap' style={{
                            flex: selectedDate.getDate() === day ? "0 0 90px" : "0 0 70px",
                            transition: 'all 0.2s ease-in-out',
                            gap: 10,
                            display: 'flex',
                            scrollSnapAlign: 'center',
                            outline: selectedDate.getDate() === day ? '2px solid orange' : '1px solid rgba(255, 255, 255, 0.2)',
                            backgroundColor: selectedDate.getDate() === day ? 'rgba(255, 165, 0, 1)' : 'transparent',
                            boxShadow: 'none',
                        }} key={index} onClick={() => handleDateChange(day)}>
                            <p className={selectedDate.getDate() === day ? 'dateSelectedText bold' : 'orange'}>
                                {days[new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day).getDay()]}
                            </p>
                            <div className={selectedDate.getDate() === day ? 'dateSelectedText bold' : ''}> {day} </div>
                            {//<div className={selectedDate.getDate() === day ? 'circle dateSelected bold' : 'circle'}></div>
                            }
                        </button>
                    ))
                }
            </div>
            <div ref={centerRef} style={{
                position: 'absolute',
                top: '0',
                left: '50%',
                height: '100%',
                width: '2px', // make it thin or invisible as needed
                backgroundColor: 'transparent', // make it invisible or any color as needed for debugging
            }} />
        </animated.div>
    );
});

export default TimePickerCarousel;