import Default from "../components/dafault";
import DefaultWrapper from "../components/defaultWrapper";
import CustomInput from "../components/miniComponents/customInput";
import { useState } from "react";
import { createId, setCompanyTimeSettings } from "../firebase";



export default function TimeSettings() {

    const [timeSettings, setTimeSettings] = useState({
        pause: 30,
        overtidsEmner: [
            {
                id: createId(),
                navn: "Overtid",
                prosent: 50,
            }
        ],
    });

    const updateCompanyDetailsTimeSettings = async () => {
        //Update company details
        console.log("updateCompanyDetailsTimeSettings");
        await setCompanyTimeSettings(timeSettings);
    }

    return (
        <DefaultWrapper
            onBack={async () => {
                await updateCompanyDetailsTimeSettings();
            }}
            lagring={true}
        >
            <div className="content-ny">
                <h2>Tids innstillinger</h2>

                <h2>Pause</h2>
                <CustomInput label="Pause" type="number" value={timeSettings.pause} onChange={(e) => setTimeSettings({ ...timeSettings, pause: e.target.value })} />

                <h2>Tids-Tilleg aleternativer</h2>
                {timeSettings?.overtidsEmner.map((emner, index) => {
                    return (
                        <div key={emner.id} className="row">
                            <div className="column small-gap">
                                <h3>Tids-navn</h3>

                                <CustomInput key={emner.id + "text"} label="Navn" type="text" value={emner.navn} onChange={(e) => {
                                    let newEmer = [...timeSettings.overtidsEmner];
                                    newEmer[index].navn = e.target.value;
                                    setTimeSettings({ ...timeSettings, overtidsEmner: newEmer });
                                }} />
                            </div>
                            <div className="column small-gap">
                                <h3>Prosent (etter 100%)</h3>
                                <CustomInput key={emner.id + "prosent"} label="Prosent" type="number" value={emner.prosent} onChange={(e) => {
                                    let newEmer = [...timeSettings.overtidsEmner];
                                    newEmer[index].prosent = e.target.value;
                                    setTimeSettings({ ...timeSettings, overtidsEmner: newEmer });
                                }} />
                            </div>
                        </div>
                    )
                }
                )}
                <div className="row flexApart">
                    <button onClick={() => {
                        let newEmer = [...timeSettings.overtidsEmner];
                        newEmer.push({
                            navn: "Overtid",
                            prosent: 50,
                            id: createId(),
                        });
                        setTimeSettings({ ...timeSettings, overtidsEmner: newEmer });
                    }}>Legg til</button>

                    <button onClick={() => {
                        let emneCopy = [...timeSettings.overtidsEmner];
                        emneCopy.pop(timeSettings.overtidsEmner.length - 1);
                        setTimeSettings({ ...timeSettings, overtidsEmner: emneCopy });
                    }}>Fjern Siste</button>

                </div>
            </div>

        </DefaultWrapper>
    )
}

