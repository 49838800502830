import { useContext, useEffect, useRef, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import ButtonListButton from "../../components/miniComponents/buttonListButton";
import { useHistory } from "react-router";
import { checkIfUserIsAdmin, createId, createInvitationLink, getCompany, getCompanyId, getCompanyUsers, getRoles, getThisCompany } from "../../firebase";
//import { get } from "http";

import { ReactComponent as UserIcon } from '../../assets/icons/material-symbols_person.svg';
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { CheckIfOnPC, UserContext } from "../../App";
import CustomModal from "../../components/miniComponents/CustomModal";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import TimeChooser from "../../components/timetracking/timeChooser";
import TimePickerCarousel from "../../components/miniComponents/calendarInput";
import { Add, FileCopyRounded, Link } from "@mui/icons-material";
import CustomInput from "../../components/miniComponents/customInput";
import { checkRolesForPermission } from "../../components/utils";
import { useTranslation } from "react-i18next";


export default function Brukere(props) {
    const { t } = useTranslation();
    const [brukere, setBrukere] = useState([]);
    const [filteredBrukere, setFilteredBrukere] = useState([]);
    const [searchString, setSearchString] = useState('');
    const history = useHistory();
    const { userContext } = useContext(UserContext);

    const [activeInvitations, setActiveInvitations] = useState([
        { endDate: new Date(), startDate: new Date(), id: 1, name: "test" },
    ]);
    const [showInvitationModal, setShowInvitationModal] = useState(false);

    useEffect(() => {
        getBrukere();
    }, []);

    useEffect(() => {
        //filterBrukere();
    }, [searchString, brukere]);

    async function getBrukere() {
        //firbase get users
        const onUpdate = (users) => {
            setBrukere(users);
            setFilteredBrukere(users);
        }
        let brukere = await getCompanyUsers(onUpdate);

        setBrukere(brukere);
    }

    /*function filterBrukere() {

        let filtered = brukere.filter((bruker) => {
            return bruker.navn.toLowerCase().includes(searchString.toLowerCase());
        });
        setFilteredBrukere(filtered);
    }*/

    function navigatoToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    return (
        <DefaultWrapper søkefelt={true} itemsToFilter={brukere} updateFilteredItems={setFilteredBrukere} noBackButton={CheckIfOnPC()}
            extraButton={checkRolesForPermission(userContext?.companyUser?.permissions, ["redigereBrukere", "admin"]) ? <button onClick={() => { setShowInvitationModal(true) }}>{t("Invitation links", "Invitasjons Lenker")} <Link /></button> : null}
        >
            <div className='content-ny'>
                <div className='row button-list'>
                    <LoadingWhileEmpty buttonList={true}>
                        {filteredBrukere.map((bruker) => {
                            return (
                                <ButtonListButton title={bruker.navn} description={""} icon={UserIcon} image={bruker?.image || bruker?.bilde} key={bruker.id}
                                    onClick={() => {
                                        navigatoToNewPage(`./brukere/${bruker.id}`, { bruker: bruker })
                                    }}
                                />
                            )
                        })}
                    </LoadingWhileEmpty>
                </div>

                <button className="bottom-right-button" onClick={() => { history.push('./brukere/nyBruker') }}>{t("New user", "Ny bruker")} <Add/></button>
                <InvitationModal invitations={activeInvitations} show={showInvitationModal} setShow={setShowInvitationModal} />
            </div>
        </DefaultWrapper>
    )
}


function InvitationModal({ invitations, show, setShow }) {
    const [invitationSettings, setInvitationSettings] = useState({
        endDate: {
            name: "1 dag",
            value: new Date().setDate(new Date().getDate() + 1),
            id: 1
        },
        role: {},
        startDate: new Date(),
        company: getCompanyId(),
        id: createId(),
        name: ""
    });
    const [invitationLink, setInvitationLink] = useState("");
    const [roller, setRoller] = useState([]);
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        const onUpdate = (roles) => {
            const viableRoles = roles.filter((role) => {
                return !role?.admin
            })

            setRoller(viableRoles)
            setInvitationSettings({
                ...invitationSettings,
                role: viableRoles[0]
            })
        };
        getRoles(onUpdate)
    }, [])


    const options = [
        {
            name: "1 dag",
            value: new Date().setDate(new Date().getDate() + 1),
            id: 1
        },
        {
            name: "2 dager",
            value: new Date().setDate(new Date().getDate() + 14),
            id: 2
        },
        {
            name: "1 uke",
            value: new Date().setDate(new Date().getDate() + 7),
            id: 3
        },
        {
            name: "1 måned",
            value: new Date().setDate(new Date().getDate() + 30),
            id: 4
        },
        {
            name: "1 år",
            value: new Date().setDate(new Date().getDate() + 365),
            id: 5
        },
        {
            name: "For alltid",
            value: new Date().setDate(new Date().getDate() + 365 * 100),
            id: 6
        }
    ]

    return (
        <CustomModal noSearch={true} visible={show} onClose={() => { setShow(false) }}>
            <div className="column">
                <CustomInput value={invitationSettings.name} label={"Navn"} onChange={(e) => {
                    setInvitationSettings({
                        ...invitationSettings,
                        name: e.target.value
                    })
                }} />
                <CustomDropdown label="Varighet" options={options} defaultValue={options[0]} selected={
                    invitationSettings.endDate
                } onChange={(e) => {
                    setInvitationSettings({
                        ...invitationSettings,
                        endDate: e
                    })
                }} />

                <CustomDropdown label="Rolle" options={roller} selected={
                    invitationSettings.role
                } onChange={(e) => {
                    console.log(e)
                    setInvitationSettings({
                        ...invitationSettings,
                        role: e
                    })
                }} />

                { checkRolesForPermission(userContext?.companyUser?.permissions, "redigereBrukere") ?
                    <button onClick={async () => {
                        const company = await getThisCompany();
                        console.log(company)
                        const link = await createInvitationLink(invitationSettings, company);
                        setInvitationLink(link);
                    }}>Lag Invitasjons Lenke</button>
                    :
                    null
                }

                {invitationLink &&
                    <div className="row center">
                        <CustomInput value={invitationLink} label={"Invitasjons-link"} disabled noIcon={true} />
                        <button className="column small-gap" onClick={() => {
                            navigator.clipboard.writeText(invitationLink);
                        }}><FileCopyRounded style={{ width: 24, height: 24 }} />
                            <p>Kopier</p>
                        </button>
                    </div>
                }
            </div>
        </CustomModal>
    )
}

