import React from "react";
import { IonButton, IonContent, IonDatetime, IonHeader, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { createId, handleUpload } from "../firebase";
import { useState, useEffect } from "react";
import { setUtstyr as setUtstyrFirebase } from "../firebase";
import Default from "../components/dafault";
import DefaultWrapper from "../components/defaultWrapper";
import CustomInput from "../components/miniComponents/customInput";
import { createMediumImageFromInput, createSmallImageFromInput } from "../components/createSmallImage";
import { useTranslation } from "react-i18next";



export default function ManageUtstyr() {
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const information = location.state;

    const [utstyr, setUtstyr] = useState(history.location.state);

    const [showModal, setShowModal] = useState(false);

    const uploadImageToFirebase = async (image) => {
        const file = image.target.files[0];
        const folderName = 'utstyr';
        const fileName = createId() + ".png";
        const link = await handleUpload(file, folderName, fileName)

        const smallFile = await createSmallImageFromInput(image)
        console.log(smallFile)
        const smallLink = await handleUpload(smallFile, folderName, fileName + '-small.png')

        const mediumFile = await createMediumImageFromInput(image)
        const mediumLink = await handleUpload(mediumFile, folderName, fileName + '-medium.png')

        setUtstyr({
            ...utstyr,
            bilde: link,
            preloadBilde: smallLink,
            mediumBilde: mediumLink
        })
    }

    // Update Database upon Change
    useEffect(() => {
        setUtstyrFirebase(utstyr)
    }, [utstyr])


    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    return (


        <div className='content-ny column'>
            <h1>{t("Equipment editing", "Utstyrs redigering")}</h1>

            <div className='column'>
                <h2>{t("Title", "Tittel")}</h2>
                <CustomInput className='input' value={utstyr.navn} onChange={(e) => {
                    setUtstyr({
                        ...utstyr,
                        navn: e.target.value
                    })
                }}></CustomInput>
            </div>

            <div className='column'>
                <h2>{t("Equipment number", "Utstyrsnummer")}</h2>
                <CustomInput className='input' value={utstyr.utstyrsNummer} onChange={(e) => {
                    setUtstyr({
                        ...utstyr,
                        utstyrsNummer: e.target.value
                    })
                }}></CustomInput>
            </div>

            <div className='column'>
                <h2>{t("Bought", "Kjøpt")}</h2>
                <CustomInput className='input' value={utstyr.kjøpt} readOnly={true} onClick={() => setShowModal(true)}></CustomInput>
            </div>

            <div className='column'>
                <label className="upload-button" htmlFor="test">{t("Upload Image", "Last opp Bilde")}</label>
                <img src={utstyr.bilde} alt='Bilde av utstyr' className="utstyr-edit-bilde" />
                <input className='input' id="test" name="test" onChange={uploadImageToFirebase} type='file'></input>
            </div>

            <IonModal isOpen={showModal} className="column padding" cssClass='column' onDidDismiss={() => setShowModal(false)}>
                <div className="column padding">
                    <IonDatetime className="stretch-height" displayFormat="DD.MM.YYYY" value={utstyr.kjøpt} onIonChange={e => setUtstyr({ ...utstyr, kjøpt: e.detail.value })} />
                    <button onClick={() => setShowModal(false)}>{t("Save", "Lagre")}</button>
                </div>
            </IonModal>
        </div>
    );
}
