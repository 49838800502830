import { useHistory } from "react-router";
import DefaultWrapper from "../../../../components/defaultWrapper";
import CustomInput from "../../../../components/miniComponents/customInput";
import { Close, Delete } from "@mui/icons-material";
import { SaveFirmSettings, createId } from "../../../../firebase";
import { useState, useEffect } from "react";

import { getCompanyUsers, getRoles } from "../../../../firebase";
import { SearchWithPopup } from "../../../../components/oppgaver/searchWithPopup";
import { useTranslation } from "react-i18next";


export default function TimeforingAktivitet() {
    const { t } = useTranslation();
    const history = useHistory();
    const [aktivitet, setAktivitet] = useState({
        navn: "",
        underAktiviteter: [],
        id: createId(),
        users: [],
        roles: [],
        number: 0,
        ...history.location.state?.aktivitet,
    });

    console.log(aktivitet);
    console.log(history.location.state)
    const oldSettings = history.location.state?.settings;


    const [usersInCompany, setUsersInCompany] = useState([]); // [user1, user2, user3
    const [filteredUsersInCompany, setFilteredUsersInCompany] = useState([]); // [user1, user2, user3
    const [rolesThatCanBeInvited, setRolesThatCanBeInvited] = useState([]); // [role1, role2, role3
    const [invitedUsers, setInvitedUsers] = useState([]); // [user1, user2, user3
    const [combinedFiltered, setCombinedFiltered] = useState([]); // [user1, user2, user3

    useEffect(() => {
        const usersFiltered = usersInCompany.filter(userInCompany =>
            !aktivitet.users.some(aktivitetUser => aktivitetUser.id === userInCompany.id)
        );
        const rolesFiltered = rolesThatCanBeInvited.filter(roleThatCanBeInvited =>
            !aktivitet.roles.some(aktivitetRole => aktivitetRole.id === roleThatCanBeInvited.id)
        );

        const combinedFiltered = [...rolesFiltered, ...usersFiltered];
        console.log(combinedFiltered);
        setCombinedFiltered(combinedFiltered);
    }, [usersInCompany, rolesThatCanBeInvited, invitedUsers, aktivitet.users, aktivitet.roles, aktivitet]);

    useEffect(() => {
        async function getRolesFromCompany() {
            //const company = await getRolesFromCompany();
            const onUpdate = (roles) => {
                console.log(roles);
                const rolesDefined = roles.map((role) => {
                    return {
                        ...role,
                        navn: role.navn + " (rolle)",
                        type: "role",
                        textColor: "orange",
                    }
                });
                setRolesThatCanBeInvited(rolesDefined);
            }
            getRoles(onUpdate);
        }
        getRolesFromCompany();
    }, []);

    useEffect(() => {
        async function getUsers() {
            const onUpdate = (users) => {
                console.log(users);
                const usersDefined = users.map((user) => {
                    return {
                        ...user,
                        navn: user.navn + " (bruker)",
                        type: "user",
                        textColor: "white",
                    }
                });
                setUsersInCompany(usersDefined);
            }
            getCompanyUsers(onUpdate);
        }
        getUsers();
    }, []);

    return (
        <DefaultWrapper lagring={true} onBack={() => {
            const newSettings = { ...oldSettings };

            let changedOld = false;
            console.log(newSettings?.aktiviteter)
            newSettings?.aktiviteter?.forEach((oldAktivitet, index) => {
                if (oldAktivitet.id === aktivitet.id) {
                    newSettings.aktiviteter[index] = aktivitet;
                    changedOld = true;
                }
            });

            if (!changedOld) newSettings.aktiviteter.push(aktivitet);

            SaveFirmSettings(newSettings, "timeforingsInstillinger");
        }} >
            <div className="content-ny">
                <CustomInput label={t("Name", "Navn")} value={aktivitet.navn} onChange={(e) => {
                    setAktivitet({ ...aktivitet, navn: e.target.value });
                }}></CustomInput>
                <div className="column flexApart stretch-width">
                    <div className="row stretch-width wrap">
                        {aktivitet.roles.map((role, index) => {
                            return (
                                <div key={role.id} className="row">
                                    <button onClick={() => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.roles.splice(index, 1);
                                        setAktivitet(aktivitetCopy);
                                    }}>{role.navn}<Close></Close></button>
                                </div>
                            )
                        })}
                        {aktivitet.users.map((user, index) => {
                            return (
                                <div key={user.id} className="row">
                                    <button onClick={() => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.users.splice(index, 1);
                                        setAktivitet(aktivitetCopy);
                                    }}>{user.navn}<Close></Close></button>
                                </div>
                            )
                        })}
                    </div>
                    <SearchWithPopup items={combinedFiltered} onChoise={
                        (item) => {
                            let parts = item.navn.split("(");
                            item.navn = parts.slice(0, -1).join("(").trim();

                            // Add the item to aktivitet based on its type
                            if (item.type === "user") {
                                setAktivitet(prevAktivitet => ({
                                    ...prevAktivitet,
                                    users: [...prevAktivitet.users, item]
                                }));
                            }
                            if (item.type === "role") {
                                setAktivitet(prevAktivitet => ({
                                    ...prevAktivitet,
                                    roles: [...prevAktivitet.roles, item]
                                }));
                            }
                        }
                    } />
                </div>

                <div className="row flexApart stretch-width">
                    <h1>{t("Activity", "Aktivitet")}</h1>
                    <button onClick={() => {
                        let newUnderAktivitet = {
                            navn: "",
                            timesats: 0,
                            id: createId(),
                        };

                        let aktivitetCopy = { ...aktivitet };
                        aktivitetCopy.underAktiviteter.push(newUnderAktivitet);
                        setAktivitet(aktivitetCopy);
                    }
                    }>{t("Add under-activity", "Legg til under aktivitet")}</button>
                </div>
                <div className="column flexApart stretch-width button-list">
                    {
                        aktivitet.underAktiviteter.map((underAktivitet, index) => {
                            return (
                                <div key={underAktivitet.id} className="row flexApart stretch-width">
                                    <CustomInput label="Navn" value={underAktivitet.navn} onChange={(e) => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter[index].navn = e.target.value;
                                        setAktivitet(aktivitetCopy);
                                    }}></CustomInput>
                                    
                                    <CustomInput type="number" label="Nummer" value={underAktivitet.number} onChange={(e) => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter[index].number = e.target.value;
                                        setAktivitet(aktivitetCopy);
                                    }}></CustomInput>

                                    <CustomInput type="number" label="Timesats" value={underAktivitet.timesats} onChange={(e) => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter[index].timesats = e.target.value;
                                        setAktivitet(aktivitetCopy);
                                    }}></CustomInput>


                                    <button onClick={() => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter.splice(index, 1);
                                        setAktivitet(aktivitetCopy);
                                    }}>
                                        <Delete></Delete>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </DefaultWrapper>
    )
}