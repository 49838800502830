import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../components/defaultWrapper";
import { acceptInvitation, checkIfUserIsAdmin, getAuthUser, getAuthUserId, getCompaniesForUser, getCompanyUser, getInvitations, logout } from "../firebase";
import ButtonListButton from "../components/miniComponents/buttonListButton";
import { useHistory } from "react-router";

//Icons
import { ReactComponent as MarkEmailIcon } from '../assets/icons/material-symbols_mark-email-unread-outline-rounded.svg';
import { ReactComponent as WorkIcon } from '../assets/icons/material-symbols_work.svg';
import { UserContext, getUserContext } from "../App";
import { useTranslation } from "react-i18next";
import LoadingWhileEmpty from "../components/miniComponents/loadingWhileEmpty";
import { setCompanyId } from "../contexts/companyContext";




export default function ChooseCompany(props) {
    const { t } = useTranslation();
    const [bedrifter, setBedrifter] = useState([]);
    const [invitasjoner, setInvitasjoner] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();

    const { userContext, setUserContext } = useContext(UserContext);

    async function navigateToNewPage(path, information) {
        //console.log(information)
        const userContextToUpdate = await getUserContext()
        setUserContext(old => {
            return { user: old.user, companyUser: userContextToUpdate.companyUser }
        });

        history.push({
            pathname: path,
            state: information
        });
    }


    async function getInvitasjoner() {
        const onUpdate = (invitasjoner) => {
            const filteredInvitasjoner = invitasjoner.filter((invitasjon) => {
                if (!invitasjon.accepted) return invitasjon;
            })
            setInvitasjoner(filteredInvitasjoner);
        }

        const userId = userContext?.user?.uid;
        //if (!userId) return console.log("No user");
        getInvitations(userContext.user, onUpdate)
    }

    async function getBedrifter() {
        //firebase get bedrifter
        const onUpdate = (bedrifter) => {
            //console.log(bedrifter);
            setBedrifter(bedrifter);
        }
        //if (!userContext?.user) return console.log("No user");
        //console.log("USER is here")
        const userId = userContext?.user?.uid;
        //console.log(userId);

        await getCompaniesForUser(userId, onUpdate)
    }

    const logOutEndFunction = () => {
        navigateToNewPage("/login");
    }

    async function getInvitationsAndCompanies() {
        await getBedrifter();
        await getInvitasjoner();
    }

    useEffect(() => {
        //getUserContext()
        //console.log("CURRENT USER CONTEXT IS " + JSON.stringify(userContext))


        getInvitationsAndCompanies()
    }, [userContext?.user?.uid, userContext?.user, userContext]);

    /*useEffect(() => {
        // try every 10 seconds to get the companies
        // if the user finds companies, stop trying
        const interval = setInterval(() => {
            getInvitationsAndCompanies()
        }, 5000);
        return () => clearInterval(interval);
    }, [])*/

    useEffect(() => {
        async function checkAdmin() {
            const result = await checkIfUserIsAdmin(userContext.user)
            console.log(result)
            setIsAdmin(result)
        }
        checkAdmin()
    }, [userContext])


    return (
        <DefaultWrapper noBackButton={true}>
            <div className='content-ny'>
                <h1>{t("It doesn't look like you've chosen a company yet", "Det ser ut som du ikke har valgt en bedrift å logge in på enda")}.</h1>
                <h2>{t("Choose a company from the list below", "Velg en bedrift fra listen under, eller opprett en ny bedrift")}.</h2>

                {invitasjoner.length > 0 &&
                    <div className="column">
                        <h1>{t("Invitations", "Invitasjoner")}</h1>
                        <div className="button-list">
                                {invitasjoner.map((invitasjon) => {
                                    //console.log(invitasjon)
                                    if (!invitasjon.accepted) return (
                                        // Needs an accept and decline button
                                        <ButtonListButton key={invitasjon.id} title={invitasjon.companyName} description={"Invitert"} icon={MarkEmailIcon} onClick={async () => {
                                            acceptInvitation(userContext.user.uid, invitasjon)

                                        }} />
                                    )
                                })}
                        </div>
                    </div>
                }

                {bedrifter.length > 0 &&
                    <div className="column">
                        <h1>{t("Companies", "Bedrifter")}</h1>
                        <div className="button-list">
                            <LoadingWhileEmpty buttonList={true} gottenInformation={bedrifter.length > 0}>
                                {bedrifter.map((bedrift) => {
                                    //console.log(bedrift)
                                    return (
                                        <ButtonListButton key={bedrift?.id + bedrift?.navn} title={bedrift?.navn} description={bedrift?.adresse} icon={WorkIcon} onClick={async () => {
                                            //Should take you to a new page to accept the invitation
                                            //console.log(bedrift?.id)
                                            setCompanyId(bedrift?.id)

                                            const userContextToUpdate = await getUserContext()
                                            setUserContext(old => {
                                                return { user: old.user, companyUser: userContextToUpdate.companyUser }
                                            });

                                            navigateToNewPage('/hjem')
                                            //navigateToNewPage('/hjem')
                                        }} />
                                    );
                                })}
                            </LoadingWhileEmpty>
                        </div>
                    </div>
                }

                {isAdmin &&
                    <div className="column">
                        <button className="button" onClick={() => {
                            // navigate to create company page
                            navigateToNewPage('/createCompany')
                        }}>{t("Create new company", "Opprett ny bedrift")}</button>
                    </div>
                }

                <div className="column">
                    <button className="button" onClick={async () => {
                        // logg out
                        await logout(logOutEndFunction);
                    }}>{t("Log out", "Logg ut")}</button>
                </div>
            </div>
        </DefaultWrapper>
    );
}