import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchWithPopup } from './searchWithPopup';

export default function ReportUtstyr(props) {
    const history = useHistory();
    const [addedUtstyr, setAddedUtstyr] = useState(
        // Is addedUtstyr an array? If not, set it to an empty array
        Array.isArray(props.addedUtstyr) ? props.addedUtstyr : []
    );

    const removeUtstyr = (id) => {
        setAddedUtstyr(addedUtstyr.filter(item => item.id !== id))
    }

    useEffect(() => {
        if (props.addedUtstyr) setAddedUtstyr(props.addedUtstyr)
    }, [props.addedUtstyr])

    useEffect(() => {
        props.onChange(addedUtstyr)
        console.log(addedUtstyr)
    }, [addedUtstyr])
    console.log(addedUtstyr)

    return (
        <div className='column'>
            <SearchWithPopup quantity={true} items={props.items} onChoise={
                (item) => {
                    console.log(item)
                    if (addedUtstyr.filter(utstyr => utstyr.id === item.id).length > 0) {
                        const newUtstyr = [...addedUtstyr];
                        addedUtstyr.find(utstyr => utstyr.id === item.id).quantity += item.quantity;
                        return setAddedUtstyr(newUtstyr)
                    }
                    setAddedUtstyr([...addedUtstyr, item])
                }
            } />
            <div className='utstyr-container row wrap padding'>
                {addedUtstyr.map((item, index) => (
                    <button onClick={() => removeUtstyr(item.id)} key={item.id} className="row small-gap">
                        <p>{item.navn} {
                            Number(item.quantity) > 1 && `x${item.quantity}`
                        }</p> <p>X</p>
                    </button>
                ))
                }
            </div>
        </div>
    );
};