import { useContext, useState } from "react";
import { AlertContext, UserContext } from "../App";
import { Avatar, IconButton } from "@mui/material";
import DefaultWrapper from "../components/defaultWrapper";
import CustomInput from "../components/miniComponents/customInput";
import { deleteUserDB, logout, updateUser } from "../firebase";
import { useTranslation } from "react-i18next";



export default function Profile() {
    const { t } = useTranslation();
    const { alertContext, setAlertContext } = useContext(AlertContext)
    const { userContext, setUserContext } = useContext(UserContext);
    const [user, setUser] = useState(userContext?.user);
    const [image, setImage] = useState(userContext?.user?.photoUrl);

    const deleteUser = () => {
        console.log("deleting.")
        setAlertContext({
            text: t("Are you sure you want to delete, This can NOT be reversed.", "Er du helt sikker på at du vill slette brukeren din? Dette kan ikke reverseres."),
            onConfirm: async () => {
                await deleteUserDB(user)
                await logout();
            },
            confirmByTyping: true,
            onConfirmText: "Slett" 
        })
    }

    return (
        <DefaultWrapper lagring={true} onBack={async (e) => {
            let newTemp = await updateUser(user, userContext?.user)
            setUserContext({ ...userContext, user: newTemp })
        }
        }>
            <div className="content-ny">
                <input type="file" accept="image/*" className="hidden" onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImage(reader.result);
                    }
                    setUser({ ...user, photoUrl: file });
                    reader.readAsDataURL(file);
                }} />

                <div className="column small-gap stretch-width center">
                    <IconButton onClick={() => document.querySelector('input[type="file"]').click()}>
                        <Avatar src={image} sx={{ width: 100, height: 100 }} />
                    </IconButton>

                    <p className="orange">{user?.displayName}</p>
                </div>


                <p className="orange">{t("Name", "Navn")}:</p>
                <CustomInput value={user?.displayName} onChange={(e) => setUser({ ...user, displayName: e.target.value })} />
                <p className="orange">{t("Email", "Epost")}:</p>
                <CustomInput value={user?.email} onChange={(e) => setUser({ ...user, email: e.target.value })} disabled={true} />

                <div className="column center">
                    <h4 className="">Dette kan ikke reverseres</h4>
                    <button className="recording" onClick={deleteUser}>Slett Bruker</button>
                </div>
            </div>
        </DefaultWrapper>
    )
}