import React, { useRef, useEffect } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/material-symbols_add.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/ic_round-minus.svg';

export default function CustomNumberInput(props) {

    const inputRef = useRef();

    const increment = () => {
        // assuming value is numeric
        const incrementedValue = parseInt(props.value || 0, 10) + 1;
        props.onChange({ target: { value: incrementedValue } });
    };

    const decrement = () => {
        // assuming value is numeric
        const decrementedValue = parseInt(props.value || 0, 10) - 1;
        if (decrementedValue >= 0) { // to prevent value from becoming negative
            props.onChange({ target: { value: decrementedValue } });
        }
    };

    useEffect(() => {
        if (props.shouldFocus) {
            inputRef.current.focus();
        }
    }, [props.value, props.shouldFocus]);


    return (
        <div className='customInput row flexApart' style={{ padding: props?.bigInput ? 20 : 10, gap: 0, minWidth: 100, width: "fit-content", ...props.outerStyle }}>
            {!props?.doNotIncludeNumber && <MinusIcon className={"orange number-button"} style={{ width: 50, height: 30 }} onClick={decrement}></MinusIcon>}
            <label className='orange custom-number-input-label'>{props?.label}</label>
            <input type={"number"} onFocus={props.onFocus} ref={inputRef} style={{ textAlign: "center", width: 45, ...props.style }} className={props.className + " center-row"} onChange={(e) => {
                console.log(e.target.value)
                e.target.value = Number(e.target.value)
                props.onChange(e)
            }} value={props.value} placeholder={props?.placeholder}></input>
            {!props?.doNotIncludeNumber && <AddIcon className={"orange number-button"} style={{ width: 50, height: 30 }} onClick={increment}></AddIcon>}
        </div>
    );
}
