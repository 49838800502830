import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab1.css';
import React, { useRef, useState, useEffect } from 'react';
import '../css/hjem.css';
import DefaultWrapper from '../components/defaultWrapper';
import { getAuthUser, updateNote, getNote, getCompanyUser, createId } from '../firebase';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { save } from 'ionicons/icons';
import WrapperForTyping from '../components/WrapperForTyping';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from 'react-i18next';


export default function NoteTakingPage() {
  const { t } = useTranslation();
  const params = useParams();

  const [note, setNote] = useState({
    text: EditorState.createEmpty(),
    id: params.id,
    navn: "...",
  });
  const [user, setUser] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty())


  useEffect(() => {
    async function getUserAndFetchNote() {
      const user = await getAuthUser();
      if (!user) return;

      const companyUser = await getCompanyUser(user);
      if (!companyUser) return;

      setUser(companyUser);

      const isIdNew = params.id === "ny" ? createId() : params.id;

      const userNote = await getNote(isIdNew, companyUser);

      if (!userNote || typeof userNote.text === 'string') {
        const emptyContentState = convertToRaw(EditorState.createEmpty().getCurrentContent());
        return setNote({
          text: emptyContentState,
          id: createId(),
          navn: t("New Note", "Nytt Notat"),
        });
      }

      const contentState = convertFromRaw(userNote.text);
      setNote({
        ...userNote,
        text: userNote.text,
      });
      setEditorState(EditorState.createWithContent(contentState));
    }

    getUserAndFetchNote();
  }, []);

  /*useEffect(() => {
    setNote({
      ...note,
      text: editorState.getCurrentContent().getPlainText(),
    });
  }, [editorState])*/

  const saveNote = debounce(async () => {
    if (user) {
      await updateNote(note, user);
    }
  }, 5000);

  /*useEffect(() => {
    return () => {
      saveNote.cancel(); // Cancel any pending debounced calls
      if (user) {
        updateNote(note, user); // Save the note immediately when unmounting
      }
    };
  }, [user, note, saveNote]);*/

  useEffect(() => {
    saveNote();
    return () => saveNote.cancel();
  }, [note])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    setNote({
      ...note,
      text: rawContentState,
    });
  };

  return (
    <WrapperForTyping onBack={() =>
      updateNote(note, user)

    }
      extraTitle={<input onChange={(e) => {
        setNote({
          ...note,
          navn: e.target.value,
        });
      }} value={note.navn} />}
    >
      <div className="note-container stretch-height column" data-color-mode="dark">

        <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName stretch-height"
          editorClassName="editorClassName stretch-height padding input-background"
          height={"100%"} value={note.text}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          preview="edit" />
      </div>
    </WrapperForTyping>
  );
}



