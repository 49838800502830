import { IonIcon } from "@ionic/react";
import { createOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import React, { useState, useEffect } from 'react';
import { ImageNotSupported } from "@mui/icons-material";
import LoadingImage from "./miniComponents/loadingImage";
import { useTranslation } from "react-i18next";

export default function UtstyrCard(props) {
    const { t } = useTranslation();
    const { navn, bilde, utstyrsNummer, id, preloadBilde } = props.utstyr;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageToDisplay, setImageToDisplay] = useState(preloadBilde);

    const history = useHistory();

    useEffect(() => {
        if (bilde) {
            const img = new Image();
            img.src = bilde;
            img.onload = () => {
                setImageToDisplay(bilde);
            };
        } else {
            setImageLoaded(true);
        }
    }, [bilde]);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    return (
        <div className='row center-column'>
            <button className='button-no-style utstyrs-card row stretch-width' onClick={() => { if (props?.onPress) props.onPress(props.utstyr); }}>
                {imageToDisplay ? <LoadingImage medium={true} item={props?.utstyr} className="utstyr-bilde" src={preloadBilde || imageToDisplay} alt="Bilde av utstyr" loading="lazy" /> : <div className="utstyr-bilde center row" style={{
                        width: 130,
                        height: 124,
                    }}>
                    <ImageNotSupported style={{
                        width: 50,
                        height: 50,
                    }} />
                </div>}
                <div className='column small-gap stretch-width padding'>
                    <h2 className="bold orange">{navn || t("No name given", "Ingen navn gitt")}</h2>
                    <p className="bold">#{utstyrsNummer}</p>
                </div>
            </button>
        </div>
    );
};
