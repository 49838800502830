



export default function CircleIconButton(props) {

    return (
        <button className="button-round" onClick={props.onClick}>
            <props.icon></props.icon>
        </button>
    );
}