import { useTranslation } from "react-i18next";
import DefaultWrapper from "../../components/defaultWrapper";
import { useContext, useState } from "react";
import { UserContext } from "../../App";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { AttachFile, AttachFileRounded, Send } from "@mui/icons-material";





export default function SelfDeclaration() {
    const { t } = useTranslation();
    const { user } = useContext(UserContext)
    const [settings, setSettings] = useState({
        fromDate: new Date(),
        reason: "",
        status: "pending",
        comments: [],
        user: user,
        files: [],
    });



    return (
        <DefaultWrapper>
            <div className="content-ny column big-gap">
                <h1>{t("Self Decleration", "Egenmelding")}</h1>
                <div className="column">
                    <CustomInput
                        label={t("From date", "Fra dato")}
                        type="date"
                        value={settings.fromDate}
                        onChange={(e) => setSettings({ ...settings, fromDate: e.target.value })}
                        noIcon={true}
                    />
                    <CustomTextareaInput
                        label={t("Reason", "Årsak")}
                        value={settings.reason}
                        onChange={(e) => setSettings({ ...settings, reason: e.target.value })}
                    />
                    <div className="row flexApart">
                        <div />
                        <div className="row">
                            <button onClick={() => {

                            }}>{t("Add files", "Legg til vedlegg")} <AttachFileRounded /></button>

                            <button onClick={() => {

                            }}>{t("Submit", "Send inn")} <Send /></button>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    )
}