
import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { createDateOutOfFirebaseTimestamp } from '../pages/timeføring/timeføring';
import { useTranslation } from 'react-i18next';


export default function TimeBarChart({ arrayOfDays, startDate, endDate }) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentFilter, setCurrentFilter] = useState("alle"); // "month" or "year" or "all"q
    const refTest = useRef(null);

    const [projects, setProjects] = useState(null);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        const projekter = [];
        const getProjects = async () => {
            arrayOfDays.forEach(day => {
                day.dager.forEach(dag => {
                    //if (!projekter.includes(dag.project)) projekter.push(dag.project);
                    dag.dates.forEach(date => {
                        if (!projekter.find(project => project?.id === date?.prosjekt?.id) && date?.prosjekt?.id) projekter.push(date.prosjekt);
                    })
                });
            });

            setProjects(projekter);
        };

        getProjects();
    }, [arrayOfDays]);

    useEffect(() => {
        const brukere = [];
        arrayOfDays.forEach(user => {
            brukere.push({ name: user.navn, id: user.id });
        });

        setUsers(brukere);

    }, [arrayOfDays]);


    useEffect(() => {
        if (currentFilter === "alle") {
            setFilteredData(multipleUsers(arrayOfDays));
        }
    }, [currentFilter, arrayOfDays]);


    useEffect(() => {
        const userArray = arrayOfDays
        const newData = userArray.map(user => {
            const dager = user.dager;
            
            const hours = calculateHoursForUser(user, dager);
            return {
                name: user.name,
                uv: hours,
                pv: hours
            };
        });



        setData([...newData].reverse());
    }, [arrayOfDays]);

    const [chartWidth, setChartWidth] = useState(300);


    useEffect(() => {
        setChartWidth(calculateWidth());
    }, [refTest.current]);

    const calculateWidth = () => {
        const minWidthOfBar = 20;


        // It can scroll, but if it is a small amount of bars, they should stretch to fill the width

        //console.log(data?.length * minWidthOfBar, refTest?.current?.clientWidth);
        if (data?.length * minWidthOfBar < refTest?.current?.clientWidth) {
            return refTest?.current?.clientWidth;
        }

        return data.length * minWidthOfBar;
    };

    // Stacked bar chart based on all the days in the month
    // Each bar is a day, and each bar is split into the different users
    //console.log(filteredData);
    const flattenedData = [];

    // Your flattenedData structure generation
    filteredData.forEach(day => {
        const entry = { date: day.date };
        day.hours.forEach(hour => {
            console.log(hour);
            const userKey = hour.bruker || "unknown"; // Use the username or ID from the hour object
            entry[userKey] = (entry[userKey] || 0) + hour.hours;
        });
        flattenedData.push(entry);
    });

    flattenedData.sort((a, b) => {
        const dateA = new Date(a.date); // convert the string date to a Date object
        const dateB = new Date(b.date);

        return dateA - dateB; // sort in ascending order
    });



    const userColors = {};

    function generateColorForUser(userId) {
        if (!userColors[userId]) {
            const newIndex = Object.keys(userColors).length;
            userColors[userId] = generateColor(newIndex);
        }
        return userColors[userId];
    }

    console.log(flattenedData);
    console.log(users);
    

    return (
        <div className='column stretch-width' ref={refTest}>
            <BarChart
                width={chartWidth}
                height={400}
                data={flattenedData}
                margin={{
                    top: 20,

                    bottom: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    angle={-60}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    tickFormatter={(text) => text.length > 7 ? `${text.substring(0, 7)}` : text}
                />

                <YAxis label={{ value: 'Timer', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend layout="horizontal" verticalAlign="top" align="center" />
                <Brush dataKey="date" height={30} stroke="#fbb104" fill='hsl(222, 19%, 11%)' y={370} x={40} />


                {
                    users && users.map(user => {
                        console.log(flattenedData);

                        return (
                        //console.log(user),
                        //console.log(flattenedData),
                        <Bar
                            key={user.id}
                            dataKey={user.id} // Use the user's ID or name here
                            name={user.name + "( " + calculateHoursForUserData(user, flattenedData) + " timer)"}
                            stackId="a"
                            fill={generateColorForUser(user.id)} // This ensures consistent color per user
                        />
                    )})
                }
            </BarChart>

            <div className="row">
                {projects?.length > 1 &&
                    <div className="column center-column stretch-width">
                        <h2>Prosjekter</h2>
                        <div className="row wrap small-gap stretch-width">
                            {projects.map((project, index) => {
                                if (!project?.id) return null;
                                return (
                                    <button onClick={() => {

                                        setFilteredData(
                                            multipleUsers(
                                                arrayOfDays.map((day) => {
                                                    return {
                                                        ...day,
                                                        dager: day.dager.filter(dag => dag.dates.find(date => date?.prosjekt?.id === project?.id))
                                                    }
                                                })
                                            )
                                        )
                                        setCurrentFilter("alle")
                                    }}
                                        key={index} className="row" style={{ color: "white", margin: "5px", padding: "10px" }}>
                                        <p>{project?.name || project?.navn || "test"}</p>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                }
                {users &&
                    <div className="column center-column stretch-width center">
                        <h2 className='center'>{t("Users", "Brukere")}</h2>
                        <div className="row wrap small-gap stretch-width center">
                            {users.map((user, index) => (
                                <button key={index} className="row" style={{
                                    padding: "10px",
                                    paddingInline: 20,
                                    background: currentFilter === user.id ? "#fbb104" : "var(--background-break)"
                                }}
                                    onClick={() => {
                                        setFilteredData(singularUser(arrayOfDays.find(u => u.id === user.id)))
                                        setCurrentFilter(user.id)
                                    }}
                                >
                                    <p style={{
                                        color: currentFilter === user.id ? "hsl(222, 19%, 11%)" : "white",
                                        fontWeight: currentFilter === user.id ? "bold" : "normal"
                                    }}>{user?.name || "test"}</p>
                                </button>
                            ))}

                            <button className="row" style={{
                                color: "white",
                                padding: "10px",
                                paddingInline: 20,
                                background: currentFilter === "alle" ? "#fbb104" : "var(--background-break)"
                            }} onClick={() => {
                                setCurrentFilter("alle")
                                setFilteredData(multipleUsers(arrayOfDays))
                            }}>
                                <p style={{
                                    color: currentFilter === "alle" ? "hsl(222, 19%, 11%)" : "white",
                                    fontWeight: currentFilter === "alle" ? "bold" : "normal"
                                }}>{t("All", "Alle")}</p>
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}
/*
function calculateHours(dates) {
    let hours = 0;
    //console.log(dates);
    dates.forEach(date => {
        if (date.endDate && typeof date.endDate.toDate === 'function') {
            const endDate = createDateOutOfFirebaseTimestamp(date.endDate);
            const startDate = createDateOutOfFirebaseTimestamp(date.startDate);
            const difference = endDate - startDate;
            const hoursToAdd = difference / 1000 / 60 / 60;
            hours += hoursToAdd;
        }
    });

    return hours;
}*/


const singularUser = (userFromArray) => {
    if (userFromArray?.length === 0) return;
    console.log(userFromArray);
    const dager = userFromArray.dager || userFromArray;

    const tillegsFarger = [
        {
            name: "Normal",
            color: "#8884d8"
        }
    ]

    const timerPerDag = dager.map(dag => {
        const dates = dag.dates;
        const hoursWorked = dates.map(date => {
            const hours = calculateTimePassed(date).value

            const addColor = () => {
                const farge = tillegsFarger.find(farge => farge?.name === date?.tilleg?.name);
                if (farge) {
                    return farge.color;
                } else {
                    return generateColor(tillegsFarger.length)
                }
            }
            //if (date?.bruker) console.log(date.bruker);
            //console.log(date);
            return { hours: hours, type: date?.tilleg?.name, farge: addColor(), bruker: date?.bruker?.id };
        });

        //returnd as a object combining all hours per type
        let arrayOfHoursWithTypes = [];
        hoursWorked.forEach(hour => {
            const index = arrayOfHoursWithTypes.findIndex(hourWithType => hourWithType.type === hour.type);
            if (index === -1) {
                arrayOfHoursWithTypes.push(hour);
            } else {
                arrayOfHoursWithTypes[index].hours += hour.hours;
            }
        });


        //console.log(arrayOfHoursWithTypes);

        return { date: dag.title, hours: arrayOfHoursWithTypes };
    });
    return timerPerDag;
}

function generateColor(index) {
    const goldenAngle = Math.PI * (3 - Math.sqrt(5));
    const hue = Math.floor(index * goldenAngle * 180 / Math.PI) % 360;

    return `hsl(${hue}, 60%, 50%)`;
}


const multipleUsers = (arrayOfDays) => {
    const userArray = arrayOfDays
    let newDataArray = [];
    console.log(userArray);
    const newArray = userArray.map(user => {
        console.log(user);
        return singularUser(user);
    });

    //console.log(newArray);

    let combinedDays = [];
    newArray.forEach(day => {
        day.forEach(dag => {
            if (!combinedDays.find(d => d.date === dag.date)) combinedDays.push(dag);

            else {
                combinedDays.find(d => d.date === dag.date).hours.push(...dag.hours);
            }
        })
    });

    //console.log(combinedDays);
    return combinedDays;
}

const calculateHoursForUser = (user, dates) => {
    console.log(dates);
    let totalHours = 0;
    dates.forEach(dag => {
        dag.dates.forEach(date => {
            if (date.bruker?.id === user.id) totalHours += Number(calculateTimePassed(date).value);
        });
    });

    return totalHours;
}

const calculateHoursForUserData = (user, dates) => {
    let totalHours = 0;
    console.log(dates);
    dates.forEach(date => {
        console.log(date[user.id]);
        if (date[user.id]) totalHours += date[user.id];
    });

    return totalHours;
}

export function calculateTimePassed(time) {
    if (!time.endDate) return '0'; // Return 0 hours if endDate is missing
    const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
    const endDate = createDateOutOfFirebaseTimestamp(time.endDate);

    // Calculate the difference in milliseconds
    const diffInMs = endDate - startDate;

    // Convert milliseconds to hours
    let hours = diffInMs / 3600000;

    // Round to the nearest 0.5 hours
    hours = Math.round(hours * 2) / 2;

    // IF WE EVER WANT TO DO SECONDS
    /*if (hours === 0 && diffInMs < 60000) {
        return {value:Math.round((diffInMs / 1000)), suffix: " s"};
    }*/
    
    // IF WE EVER WANT TO DO MINUTES
    /*if (hours === 0) {
        return {value:Math.round((diffInMs / 60000)), suffix: " m"};
    }*/

    // Replace . with , for Norwegian formatting
    return {value:hours, suffix: " t"};
}