import { IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";


export default function MathInput(props) {

    const [inputValue, setInputValue] = useState({
        value: "",
        unit: "cm"
    });

    useEffect(() => {
        props.onChange(inputValue)
    }, [inputValue])

    return (
        <div className="row math-input">
            <input
                className="math-input-text"
                type="number"
                value={inputValue.value}
                onChange={(e) => setInputValue({ ...inputValue, value: e.target.value })}
                placeholder={"0"}
            />
            <IonSelect
                className="math-input-select"
                interface="action-sheet"
                value={inputValue.unit}
                onIonChange={(e) => setInputValue({ ...inputValue, unit: e.detail.value })}
            >
                <IonSelectOption value="cm">cm</IonSelectOption>
                <IonSelectOption value="dm">dm</IonSelectOption>
                <IonSelectOption value="m">m</IonSelectOption>
            </IonSelect>

        </div>
    );
}