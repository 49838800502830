import { useEffect, useState } from "react"
import { getCompanyUsers, getTimeListForUser, getTimeListForUserWithRestricitons } from "../../../firebase";
import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { Add, AddRounded, ArrowLeft, ArrowLeftRounded, ArrowRightRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useHistory } from "react-router";
import LoadingWhileEmpty from "../../../components/miniComponents/loadingWhileEmpty";
import { useTranslation } from "react-i18next";




export default function ArbeidsplanKalender() {
    const { t } = useTranslation();
    const history = useHistory();
    const [ansatte, setAnsatte] = useState([])
    const [ansatteMedTider, setAnsatteMedTider] = useState([])
    const norskUke = getWeekStartEnd(new Date());
    const [tidsRamme, setTidsRamme] = useState({ fra: norskUke.start, til: norskUke.end, nummer: norskUke.nummer });
    const [dager, setDager] = useState([])



    useEffect(() => {
        const dates = getDatesBetween(tidsRamme.fra, tidsRamme.til);
        // Now you can use the dates array
        setDager(dates);
    }, [tidsRamme]);

    useEffect(() => {
        const onUpdate = (ansatte) => {
            setAnsatte(ansatte)
        }
        getCompanyUsers(onUpdate)
    }, [])

    useEffect(() => {
        //let ansatteMedTider = [];

        const onUpdate = (timer, brukerId) => {
            let ansatt = ansatte.find((ansatt) => ansatt.id === brukerId)
            ansatt.timer = timer;



            setAnsatteMedTider((prevState) => {
                let ansatteMedTider = [...prevState.filter((ansatt) => ansatt.id !== brukerId), ansatt]
                return ansatteMedTider.sort(sortByNavn)
            })
        }

        const getTimerTilGodkjenning = async () => {
            ansatte.forEach(async (bruker) => {
                await getTimeListForUserWithRestricitons(onUpdate, bruker.id, tidsRamme.fra, tidsRamme.til)
            })
        }
        getTimerTilGodkjenning();
    }, [ansatte, tidsRamme])


    return (
        <div className="ny-content column">
            <div className="arbeidsplan-container">
                <div className="arbeidsplan-kalender-ny column card">
                    <div className="arbeidsplan-kalendender-uke-område stretch-width">
                        <div className="arbeidsplan-kalender-uke-felt row center-column">
                            <button onClick={() => {
                                const uke = getWeekStartEnd(new Date(tidsRamme.fra.getTime() - 7 * 24 * 60 * 60 * 1000));
                                setTidsRamme({ fra: uke.start, til: uke.end, nummer: uke.nummer })
                            }}><ArrowLeftRounded /></button>
                            <h2 className="arbeidsplan-kalender-uke-nummer">{t("Week", "Uke")} {tidsRamme.nummer}</h2>
                            <button onClick={() => {
                                const uke = getWeekStartEnd(new Date(tidsRamme.fra.getTime() + 7 * 24 * 60 * 60 * 1000));
                                setTidsRamme({ fra: uke.start, til: uke.end, nummer: uke.nummer })
                            }}><ArrowRightRounded />
                            </button>
                        </div>
                    </div>

                    <div className="arbeidsplan-kalender-forklaring row center-column">
                        <h1 className="ansatt-kalender-felt">{t("Employee", "Ansatt")}</h1>
                        {// Loop gjennom alle dagene i uken
                            dager.map((dag, index) => {
                                return (
                                    <div className="dag-kalender-felt calender-background-contrast row noGap center-row" key={index}>
                                        <p >{getDayName(dag, t)}</p>
                                        <h2 className="dato-kalender-felt">{dag.getDate()}</h2>
                                    </div>
                                )
                            })
                        }
                        <div className="dag-kalender-felt calender-background-contrast column noGap">
                            <h3 className="dato-kalender-felt">{t("Amount of hours set up", "Antall timer satt opp")}</h3>
                        </div>
                    </div>
                    <div className="arbeidsplan-kalender-ansatte-område column">
                        <LoadingWhileEmpty loading={ansatteMedTider.length === 0}>
                            {ansatteMedTider.map((ansatt, index) => {
                                //console.log(ansatt)
                                return (
                                    <div className="arbeidsplan-kalender-ansatt-felt row" key={index}>
                                        <div className="ansatt-kalender-felt row center-column">
                                            <Avatar src={ansatt.profilbilde}
                                            /> <h2>{ansatt.navn}</h2>
                                        </div>
                                        {dager.map((dag, index) => {
                                            const dagensTimerUnsorted = checkIfTimerIsInDate(ansatt.timer, dag)
                                            const dagensTimer = dagensTimerUnsorted ? dagensTimerUnsorted.sort((a, b) => a.fra - b.fra) : null;
                                            if (dagensTimer) return (
                                                <div className="dag-combination-kalender-felt">
                                                    <div className={"dag-kalender-felt calender-background column small-gap"} key={index}>
                                                        {dagensTimer ? dagensTimer.map((tidsperiode, idx) => (
                                                            // If time is on a weekend, make it red
                                                            <div className={"dato-kalender-felt " +  (tidsperiode.gjennomført ? "gjennomført" : "") + ( dag.getDay() === 0 || dag.getDay() === 6 ? " weekend" : "")
                                                        } key={idx} onClick={(e) => {
                                                                history.push({
                                                                    pathname: "/timeAdmin/editTime",
                                                                    state: {
                                                                        time: {
                                                                            ...tidsperiode
                                                                        }
                                                                    }
                                                                });
                                                            }}>
                                                                <div>{tidsperiode?.prosjekt?.navn}</div>
                                                                <div>{tidsperiode.fra.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                                                                <div>{tidsperiode.til.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                                                            </div>
                                                        )) : null}
                                                    </div>
                                                    <div className="dato-kalender-felt-add-container">
                                                        <button className="dato-kalender-felt-add" onClick={(e) => {
                                                            history.push({
                                                                pathname: "/timeAdmin/nytid",
                                                                state: {
                                                                    time: {
                                                                        // Dato should be in a: 20/12/2021 format
                                                                        dato: `${dag.getFullYear()}-${dag.getMonth() + 1}-${dag.getDate()}`,
                                                                        people: [ansatt],
                                                                    }
                                                                }
                                                            });
                                                        }}><AddRounded /></button>
                                                    </div>
                                                </div>
                                            )

                                            return (
                                                <button className="dag-kalender-felt-add" key={index} onClick={(e) => {
                                                    history.push({
                                                        pathname: "/timeAdmin/nytid",
                                                        state: {
                                                            time: {
                                                                // Dato should be in a: 20/12/2021 format
                                                                dato: `${dag.getFullYear()}-${dag.getMonth() + 1}-${dag.getDate()}`,
                                                                people: [ansatt],
                                                            }
                                                        }
                                                    });
                                                }
                                                }>
                                                    <AddRounded />
                                                </button>
                                            )
                                        }
                                        )}



                                        <div className="dag-kalender-felt calender-background-contrast column noGap">
                                            <h2 className="dato-kalender-felt">{calculateAnsattTimer(ansatt.timer)} {t("h", "t")}</h2>
                                        </div>


                                    </div>
                                )
                            })}
                        </LoadingWhileEmpty>
                    </div>
                </div>
            </div >
        </div >
    )
}

function getWeekStartEnd(date) {
    // First day of this week
    const weekStart = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    weekStart.setUTCDate(weekStart.getUTCDate() - (weekStart.getUTCDay() || 7) + 1);

    // Last day of this week
    const weekEnd = new Date(weekStart.getTime());
    weekEnd.setUTCDate(weekEnd.getUTCDate() + 6);
    weekEnd.setUTCHours(23);

    return { start: weekStart, end: weekEnd, nummer: getWeekNumber(weekStart) };
}

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - ((d.getUTCDay() + 6) % 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
}

function getDatesBetween(start, end) {
    const dates = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}


function checkIfTimerIsInDate(timer, date) {
    const timerInDate = [];
    timer.forEach((time) => {
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        if (startDate.getDate() === date.getDate() && startDate.getMonth() === date.getMonth() && startDate.getFullYear() === date.getFullYear()) {
            timerInDate.push({ ...time, fra: startDate, til: endDate, id: time.id, gjennomført: time.gjennomført, prosjekt: time.prosjekt, kommentar: time.kommentar });
        }
    });
    return timerInDate.length > 0 ? timerInDate : false;
}


const sortByNavn = (a, b) => {
    if (a.navn < b.navn) {
        return -1;
    }
    if (a.navn > b.navn) {
        return 1;
    }
    return 0;
}

function getDayName(date, t) {
    const days = [t("Sun","Søn"), t("Mon","Man"), t("Tue","Tir"), t("Wed", "Ons"), t("Thu", "Tor"), t("Fri", "Fre"), t("Sat","Lør")];
    return days[date.getDay()];
}

function calculateAnsattTimer(timer) {
    let timerSum = 0;
    timer.forEach((time) => {
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        timerSum += endDate.getTime() - startDate.getTime();
    });

    // Convert milliseconds to hours
    let hours = timerSum / (1000 * 60 * 60);

    // Round to the nearest 0.5
    hours = Math.round(hours * 2) / 2;

    return hours;
}
